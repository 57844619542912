import classNames from "classnames";
import { Dayjs } from "dayjs";
import { Dashboard } from "i18n/localizationText";
import { Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { dayjs } from "utils/dateTimeHelpers";
import "./ExpirationLabel.css";

type ExpirationLabelProps = {
  expirationDate: string;
  boughtDate?: string;

  showText?: boolean;
  dateRangeClassName?: string;
  labelClassName?: string;
};

const ExpirationLabel = (props: ExpirationLabelProps) => {
  let {
    expirationDate,
    boughtDate,
    showText,
    dateRangeClassName,
    labelClassName,
  } = props;
  let showLabel = showText || false;

  let getDaysDifference = (first: Dayjs, second: Dayjs) => {
    return Math.round(first.diff(second, "days"));
  };

  let getTextClassName = (delta: number) => {
    if (delta <= 0) {
      return "expiration-label-stopped";
    } else if (delta <= 3) {
      return "expiration-label-expiring-danger";
    } else if (delta <= 5) {
      return "expiration-label-expiring-warning";
    } else {
      return "expiration-label-expiring-active";
    }
  };

  let now = dayjs();
  let delta = getDaysDifference(dayjs(expirationDate), now);
  let className = classNames("expiration-label", getTextClassName(delta));

  let renderDateRange = () => {
    if (boughtDate) {
      return (
        <span className={classNames(className, dateRangeClassName)}>
          {dayjs(boughtDate).format("L")} - {dayjs(expirationDate).format("L")}
        </span>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Stack>
      {renderDateRange()}
      {showLabel ? (
        <span className={classNames(className, labelClassName)}>
          <FormattedMessage
            id={Dashboard.ServerStateExpiring}
            values={{
              days: delta,
            }}
          />
        </span>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default ExpirationLabel;
