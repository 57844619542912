import IconButton from "components/IconButton";
import IconCross from "components/Icons/IconCross";
import StyledButton, { StyledButtonType } from "components/StyledButton";
import React from "react";
import { Col, Modal, ModalProps, Row, Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./ConfirmationModal.css";

type ConfirmationModalProps = {
  titleTextId: string;
  titleTextValues?: Record<string, React.ReactNode>;
  descriptionTextId: string;
  descriptionTextValues?: Record<string, React.ReactNode>;
  submitTextId: string;
  submitTextValues?: Record<string, React.ReactNode>;
  cancelTextId?: string;
  cancelTextValues?: Record<string, React.ReactNode>;

  showCloseButton?: boolean;
  showCross?: boolean;

  onSubmit: () => void;
  onCancel?: () => void;
} & ModalProps;

const ConfirmationModal = (props: ConfirmationModalProps) => {
  let {
    titleTextId, titleTextValues,
    descriptionTextId, descriptionTextValues,
    submitTextId, submitTextValues,
    cancelTextId, cancelTextValues,
    showCloseButton,
    showCross,
    onSubmit,
    onCancel,
    ...modalProps
  } = props;

  const hideAction = onCancel || modalProps.onHide;

  let renderCancelButton = () => {
    if (showCloseButton) {
      return (
        <Col>
          <StyledButton className="w-100" buttonType={StyledButtonType.Disabled} onClick={hideAction}>
            <FormattedMessage id={cancelTextId} values={cancelTextValues} />
          </StyledButton>
        </Col>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Modal {...modalProps} centered>
      {showCross && <IconButton
        clear
        icon={<IconCross color="#212529" />}
        className="dialog-close-button"
        onClick={() => hideAction?.call(this)}
      />}
      <Modal.Body className="confirmation-modal-container">
        <Stack gap={3}>
          <div className="dialog-header">
            <FormattedMessage id={titleTextId} values={titleTextValues} />
          </div>
          <div className="confirmation-modal-description">
            <FormattedMessage id={descriptionTextId} values={descriptionTextValues} />
          </div>
          <Row gap={2}>
            <Col>
              <StyledButton className="w-100" onClick={onSubmit}>
                <FormattedMessage id={submitTextId} values={submitTextValues} />
              </StyledButton>
            </Col>
            {renderCancelButton()}
          </Row>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
