import { PaymentProvider } from "./purchaseModels";

export enum VirtualMachineStatus {
  Unknown,
  Creating,
  Running,
  Expiring,
  Expired,
  Deleted,
}

export interface MachineShortInfo {
  name: string;
  diskSize?: string;
  duration?: string;
  isStaticIp: boolean;
}

export interface VirtualMachine {
  id: string;
  publicName: string;
  lastStartDate: string;
  dueDate: string;
  ipAddress?: string;
  status: VirtualMachineStatus;
  subscriptionInfo?: VirtualMachineSubscriptionInfo;
}

export interface VirtualMachineSubscriptionInfo {
  provider: PaymentProvider;
  isPaused: boolean;
}

export type VirtualMachineShortModel = {
  machineInfo: VirtualMachineInfo;
} & VirtualMachine;

export type VirtualMachineFullModel = {
  machineInfo: VirtualMachineFullInfo;
} & VirtualMachine;

export type VirtualMachineInfo = FtpVirtualMachineInfo;

export interface FtpVirtualMachineInfo {
  Size: string;
}

export type VirtualMachineFullInfo = FtpVirtualMachineFullInfo;

export type FtpVirtualMachineFullInfo = {
  Total: string;
  Used: string;
} & FtpVirtualMachineInfo;

export interface ChangeVirtualMachineNameRequestModel {
  machineId: string;
  newName: string;
}
