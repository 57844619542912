import classNames from "classnames";
import ExpirationLabel from "components/ExpirationLabel";
import FlatCard from "components/FlatCard";
import IconButton from "components/IconButton";
import IconClipboard from "components/Icons/IconClipboard";
import IconClockHistory from "components/Icons/IconClockHistory";
import IconCross from "components/Icons/IconCross";
import IconPause from "components/Icons/IconPause";
import IconPlay from "components/Icons/IconPlay";
import { Dashboard } from "i18n/localizationText";
import { RequestState } from "models/common";
import { VirtualMachineShortModel } from "models/virtualMachineModels";
import { Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { formatFtpIpAddress } from "utils/ftpHelpers";
import "./MachineShortInfo.css";

type MachineShortInfoProps = {
  machine: VirtualMachineShortModel;
  subscriptionOperationState: RequestState;
  onRenewClick?: (machineId: string) => void;
  onPauseClick?: (machineId: string, status: boolean) => void;
  onCancelClick?: (machineId: string) => void;
};

const MachineShortInfo = (props: MachineShortInfoProps) => {
  let { machine, subscriptionOperationState, onRenewClick, onPauseClick, onCancelClick } = props;
  let address = formatFtpIpAddress(machine.ipAddress || "");

  let renderControls = (machine: VirtualMachineShortModel) => {
    if (machine.subscriptionInfo) {
      let isPaused = machine.subscriptionInfo.isPaused;
      let disabledState = subscriptionOperationState === RequestState.Loading ? 'disabled' : '';
      return (
        <>
          <Stack
            direction="horizontal"
            gap={1}
            className={classNames(
              isPaused ? "success-text" : "warning-text",
              "cursor-pointer",
              disabledState)}
            onClick={() => onPauseClick?.call(this, machine.id, !isPaused)}
          >
            {
              machine.subscriptionInfo.isPaused
                ? (
                  <>
                    <IconPlay color="#19B500" />
                    <FormattedMessage id={Dashboard.UnpauseSubscription} />
                  </>
                ) : (
                  <>
                    <IconPause color="#FFB801" />
                    <FormattedMessage id={Dashboard.PauseSubscription} />
                  </>
                )
            }
          </Stack>
          <Stack
            direction="horizontal"
            gap={1}
            className={classNames(
              "error-text cursor-pointer",
              disabledState)}
            onClick={() => onCancelClick?.call(this, machine.id)}
          >
            <IconCross color="#C00000" />
            <FormattedMessage id={Dashboard.CancelSubscription} />
          </Stack>
        </>
      );
    } else {
      return (
        <Stack
          direction="horizontal"
          gap={1}
          className="primary-text cursor-pointer"
          onClick={() => onRenewClick?.call(this, machine.id)}
        >
          <IconClockHistory color="#19B500" />
          <FormattedMessage id={Dashboard.RenewSubscription} />
        </Stack>
      );
    }
  };

  return (
    <FlatCard className="server-info-container">
      <Stack className="server-info-name-ip">
        <span className="server-info-name">{machine.publicName}</span>
        <Stack direction="horizontal" gap={2}>
          <div className="primary-text">{address}</div>
          <IconButton
            clear
            icon={<IconClipboard color="#212529" />}
            onClick={() => navigator.clipboard.writeText(address)}
          />
        </Stack>
      </Stack>
      <Stack className="server-info-period" gap={1}>
        <div className="primary-text">
          <FormattedMessage id={Dashboard.ValidityPeriod} />
        </div>
        <ExpirationLabel
          showText={false}
          boughtDate={machine.lastStartDate}
          expirationDate={machine.dueDate}
          dateRangeClassName="server-info-period-range"
        />
        {renderControls(machine)}
      </Stack>
    </FlatCard>
  );
};

export default MachineShortInfo;
