import {
  PaymentInformation,
  PaymentProvider,
  Purchase,
  PurchaseType
} from "models/purchaseModels";
import { MachineShortInfo } from "models/virtualMachineModels";

export var getAppropriatePurchase = (
  purchases: Purchase[],
  info: MachineShortInfo
): PaymentInformation | undefined => {
  let paymentInfo: PaymentInformation | undefined;
  let purchase = purchases?.find(
    (purchase) =>
      purchase.provider === PaymentProvider.Stripe &&
      purchase.additionalData.Size === info.diskSize
  );
  if (purchase) {
    let plan = purchase.plans.find(
      (plan) =>
        plan.duration === info.duration
    );
    if (plan) {
      paymentInfo = {
        productId: purchase.productId,
        additionalId: plan.priceId,
      };
    }
  }
  return paymentInfo;
};

export var getPurchase = (
  purchases: Purchase[],
  priceId: string
): PaymentInformation | undefined => {
  let paymentInfo: PaymentInformation | undefined;
  let purchase = purchases?.find(
    (purchase) =>
      purchase.provider === PaymentProvider.Stripe &&
      purchase.plans.some(plan => plan.priceId == priceId)
  );
  if (purchase) {
    let plan = purchase.plans.find(
      (plan) =>
        plan.priceId === priceId
    );
    if (plan) {
      paymentInfo = {
        productId: purchase.productId,
        additionalId: plan.priceId,
      };
    }
  }
  return paymentInfo;
};
