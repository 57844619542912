import { MachineShortInfo } from "models/virtualMachineModels";
import { ClientType } from "./common";

export enum PaymentProvider {
  Microsoft = 0,
  Stripe = 1,
}

export enum PurchaseType {
  OneTime = 0,
  Subscription = 1,
}

export interface PaymentInformation {
  productId: string;
  additionalId: string;
}

export type CheckoutInfomation = PaymentInformation &
  MachineShortInfo &
  CreateMachineAdditionalData;

export interface PurchasesModel {
  purchases: Purchase[];
  stripeKey: string;
}

export interface Purchase {
  id: string;
  provider: PaymentProvider;
  productId: string;
  plans: Plan[];
  additionalData: PurchaseAdditionalData;
}

export interface Plan {
  priceId: string;
  duration: string;
  type: PurchaseType;
  additionalData: PlanAdditionalData;
}

export interface GetPurchasesResponse {
  purchases: Purchase[],
  stripeKey: string
}

export type CreateMachineStripe = {
  publicName: string;
  isStaticIp: boolean;
  paymentInfo: PaymentInformation;
  additionalInfo: CreateMachineAdditionalData;
  client: ClientType;
}

export type RenewMachineStripe = {
  machineId: string;
  productId: string;
  additionalId: string;
  client: ClientType;
};

export interface CancelSubscriptionStripe {
  machineId: string;
}

export interface PauseSubscriptionStripe {
  machineId: string;
  pause: boolean;
}

export type PurchaseAdditionalData = FtpPurchaseAdditionalData;

export interface FtpPurchaseAdditionalData {
  Size: string;
}

export type PlanAdditionalData = FtpPlanAdditionalData;

export interface FtpPlanAdditionalData {}

export type CreateMachineAdditionalData = CreateFtpMachineAdditionalData;

export interface CreateFtpMachineAdditionalData {
  Username: string;
  Password: string;
}
