import { AxiosError } from "axios";
import { RequestError } from "models/common";
import {
  AddUserRequestModel,
  ChangeUserPasswordRequestModel,
  FtpUser,
} from "models/ftpModels";
import { authenticatedFtpAxios, handleAxiosError } from "./apiService";

const ftpEndpoint = (subRoute: string): string => subRoute;

export var getUsers = (token: string, machineId: string) =>
  new Promise<FtpUser[]>((resolve, reject) => {
    authenticatedFtpAxios(token)
      .get<FtpUser[]>(ftpEndpoint("/getUsers"), {
        params: { machineId: machineId },
      })
      .then((tokenResponse) => {
        resolve(tokenResponse.data as FtpUser[]);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var addUser = (token: string, model: AddUserRequestModel) =>
  new Promise<any>((resolve, reject) => {
    authenticatedFtpAxios(token)
      .post(ftpEndpoint("/addUser"), model)
      .then(() => {
        resolve(null);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var changeUserPassword = (
  token: string,
  model: ChangeUserPasswordRequestModel
) =>
  new Promise<any>((resolve, reject) => {
    authenticatedFtpAxios(token)
      .put(ftpEndpoint("/changeUserPassword"), model)
      .then(() => {
        resolve(null);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var blockUser = (token: string, machineId: string, username: string) =>
  new Promise<any>((resolve, reject) => {
    authenticatedFtpAxios(token)
      .put(ftpEndpoint("/blockUser"), null, {
        params: { machineId: machineId, username: username },
      })
      .then(() => {
        resolve(null);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var unblockUser = (token: string, machineId: string, username: string) =>
  new Promise<any>((resolve, reject) => {
    authenticatedFtpAxios(token)
      .put(ftpEndpoint("/unblockUser"), null, {
        params: { machineId: machineId, username: username },
      })
      .then(() => {
        resolve(null);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var removeUser = (token: string, machineId: string, username: string) =>
  new Promise<any>((resolve, reject) => {
    authenticatedFtpAxios(token)
      .delete(ftpEndpoint("/removeUser"), {
        params: { machineId: machineId, username: username },
      })
      .then(() => {
        resolve(null);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });
