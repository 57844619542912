import FlatCard from "components/FlatCard";
import IconButton from "components/IconButton";
import IconRefresh from "components/Icons/IconRefresh";
import { Dashboard } from "i18n/localizationText";
import { RequestState } from "models/common";
import { HistoryEntry as HistoryEntryModel } from "models/userModels";
import { Container, Spinner, Stack } from "react-bootstrap";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import HistoryEntry from "./components/HistoryEntry";
import "./History.css";

type HistoryViewProps = {
  entries?: HistoryEntryModel[];
  entriesState: RequestState;

  searchHighlightedText?: string;
  onRefresh?: () => void;
};

const HistoryView = (props: HistoryViewProps) => {
  let { entries, entriesState, searchHighlightedText, onRefresh } = props;
  const intl = useIntl();
  const messages = defineMessages({
    refreshTooltip: {
      id: Dashboard.Refresh,
    }
  });

  entries = entries || [];

  return (
    <Container fluid className="history-container">
      <Stack
        className="align-items-start mt-2 mb-3"
        direction="horizontal"
        gap={3}
      >
        <div className="dashboard-machine-list-title">
          <FormattedMessage id={Dashboard.SubscriptionHistory} />
        </div>
        <IconButton
          icon={<IconRefresh />}
          clear
          title={intl.formatMessage(messages.refreshTooltip)}
          onClick={() => onRefresh?.call(this)}
        />
      </Stack>
      <FlatCard className="history-card">
        {entriesState === RequestState.Loading ? (
          <Spinner animation="border" />
        ) : (
          <Stack className="history-entries-container" gap={2}>
            {entries.map((entry) => (
              <HistoryEntry key={entry.id} entry={entry} highlightText={searchHighlightedText} />
            ))}
          </Stack>
        )}
      </FlatCard>
    </Container>
  );
};

export default HistoryView;
