import Header from "components/Header";
import { Dashboard, General } from "i18n/localizationText";
import {
  DASHBOARD_ABOUT_ABSOLUTE,
  DASHBOARD_HISTORY_ABSOLUTE,
  DASHBOARD_HOME_ABSOLUTE
} from "navigation/routes";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./Dashboard.css";

type DashboardViewProps = {};

const DashboardView = (props: DashboardViewProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container className="dashboard-body">
      <Header showSearch={true} />
      <Row className="dashboard-content-container">
        <Col xs={2} className="p-0">
          <Nav
            defaultActiveKey={DASHBOARD_HOME_ABSOLUTE}
            activeKey={location.pathname}
            className="flex-column"
          >
            <Nav.Link
              eventKey={DASHBOARD_HOME_ABSOLUTE}
              onClick={() => navigate(DASHBOARD_HOME_ABSOLUTE)}
              className="vertical-nav-link"
            >
              <FormattedMessage id={Dashboard.MySubscription} />
            </Nav.Link>
            <Nav.Link
              eventKey={DASHBOARD_HISTORY_ABSOLUTE}
              onClick={() => navigate(DASHBOARD_HISTORY_ABSOLUTE)}
              className="vertical-nav-link"
            >
              <FormattedMessage id={Dashboard.SubscriptionHistory} />
            </Nav.Link>
            <Nav.Link
              eventKey={DASHBOARD_ABOUT_ABSOLUTE}
              onClick={() => navigate(DASHBOARD_ABOUT_ABSOLUTE)}
              className="vertical-nav-link"
            >
              <FormattedMessage id={General.About} />
            </Nav.Link>
          </Nav>
        </Col>
        <Col className="d-flex p-0">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardView;
