import { Error } from 'i18n/localizationText';
import { FieldError } from 'react-hook-form';
import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import { LocaleObject } from 'yup/lib/locale';

const yupConfig : LocaleObject = {
  mixed: {
    default: Error.InvalidField,
    required: Error.IsRequired
  },
  string: {
    min: ({min}) => ({ message: Error.IncorrectMinLength, values: {length: min} }),
    max: ({max}) => ({ message: Error.IncorrectMaxLength, values: {length: max} })
  }
};

yup.setLocale(yupConfig);

export var localizeYupError = (intl: IntlShape, error?: FieldError) => {
  let res = "";
  if (error) {
    let message = error.message as any;
    res = (message instanceof Object)
      ? intl.formatMessage({ id: message.message }, message.values)
      : intl.formatMessage({ id: message });
  }
  return res;
};

export default yup;