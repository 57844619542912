import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(duration);
dayjs.extend(localizedFormat)

export var parseDuration = (input: string) => {
  let result = null;
  let match = input.match(/^(?<days>\d*)\.?(?<hours>\d*):(?<minutes>\d*):(?<seconds>\d*)\.?(?<milliseconds>\d*)$/);
  if (match && match.groups) {
    let groups = match.groups;
    let parsedValues : Record<string, number> = {};
    Object.keys(groups).forEach(key => {
      parsedValues[key] = parseInt(groups[key])
    })
    result = dayjs.duration(parsedValues);
  }
  return result;
}

export { dayjs };