import Divider from "components/Divider";
import FlatCard from "components/FlatCard";
import IconArrowRight from "components/Icons/IconArrowRight";
import StyledButton from "components/StyledButton";
import { General, Landing } from "i18n/localizationText";
import { BuyServerViewModel } from "models/viewModels";
import { MachineShortInfo } from "models/virtualMachineModels";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "redux/store";
import { getStripePrice } from "services/paymentService";
import { parseDuration } from "utils/dateTimeHelpers";
import { getAppropriatePurchase } from "utils/purchaseHelpers";
import "./BuyServer.css";

type SummaryProps = {
  onSummarySubmit?: (info: MachineShortInfo) => void;
};

const Summary = (props: SummaryProps) => {
  let { onSummarySubmit } = props;
  const [totalPrice, setTotalPrice] = useState(0);

  const state = useAppSelector((x) => x.purchasing);
  const { stripeKey } = state;

  const { watch, handleSubmit } = useFormContext<BuyServerViewModel>();

  const intl = useIntl();
  const messages = defineMessages({
    spaceValueFormat: {
      id: General.DiskSpaceValueFormat,
    },
    durationValueFormat: {
      id: General.DurationValueFormat,
    },
  });

  let formState = watch();
  let ipLocalizationId = formState.isStaticIp
    ? General.IpAddressStatic
    : General.IpAddressDynamic;
  let duration = formState.duration
    ? parseDuration(formState.duration)
    : undefined;

  useEffect(() => {
    refreshPrice();
  }, [formState.duration, formState.diskSize]);

  let onSubmitPressed = (data: BuyServerViewModel) => {
    onSummarySubmit?.call(this, data);
  };

  let refreshPrice = () => {
    let paymentInfo = getAppropriatePurchase(state.purchases || [], formState);
    if (stripeKey && paymentInfo?.additionalId) {
      getStripePrice(stripeKey, paymentInfo.additionalId)
        .then((price: number) => setTotalPrice(price))
        .catch((err) => console.log(err));
    }
  };

  return (
    <FlatCard className="landing-summary-container">
      <Container fluid>
        <h3 className="landing-summary-title">
          <FormattedMessage id={Landing.OrderSummary} />
        </h3>

        <Row>
          <Col xs="auto">
            <FormattedMessage id={General.ServerName} />
          </Col>
          <Col className="summary-info-entry-value">{watch("name")}</Col>
        </Row>
        <Row>
          <Col xs="auto">
            <FormattedMessage id={General.DiskSize} />
          </Col>
          <Col className="summary-info-entry-value">
            {formState.diskSize &&
              intl.formatMessage(messages.spaceValueFormat, {
                size: formState.diskSize,
              })}
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            <FormattedMessage id={General.SubscriptionDuration} />
          </Col>
          <Col className="summary-info-entry-value">
            {duration &&
              intl.formatMessage(messages.durationValueFormat, {
                days: duration?.days(),
              })}
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            <FormattedMessage id={General.IpAddressLabel} />
          </Col>
          <Col className="summary-info-entry-value">
            <FormattedMessage id={ipLocalizationId} />
          </Col>
        </Row>

        <Divider className="summary-divider" />

        <h1 className="text-center">{`${totalPrice} $`}</h1>

        <Row className="justify-content-center">
          <StyledButton
            className="button-continue"
            type="submit"
            onClick={handleSubmit(onSubmitPressed)}
          >
            <FormattedMessage id={General.Continue} />
            <IconArrowRight className="button-continue-arrow" color="white" />
          </StyledButton>
        </Row>
      </Container>
    </FlatCard>
  );
};

export default Summary;
