import DeviceDetector from "device-detector-js";

const contactUsEmail = 'support@tidalmediainc.com';
const subject = 'Private FTP Server - Contact Us';
const bodyHeading = 'Dear customer, tell us what you think! Please, enter your feedback:';

export var createContactUsLink = () => {
    let deviceInfo = createDeviceInformationBlock();
    let body = [bodyHeading, '', '', '', '', deviceInfo].join('\n');

    return createMailtoLink(
        contactUsEmail,
        subject,
        encodeURIComponent(body)
    );
};

export var createMailtoLink = (to: string, subject: string, body?: string, cc?: string, bcc?: string) => {
    const ccPart = Boolean(cc) ? "cc="+cc : '';
    const bccPart = Boolean(bcc) ? "bcc="+bcc : '';
    const bodyPart = Boolean(body) ? "body="+body : '';
    const queryParts = [ccPart, bccPart, 'subject='+subject, bodyPart];
    return `mailto:${to}?${queryParts.join('&')}`;
}

var createDeviceInformationBlock = () => {
    const deviceDetector = new DeviceDetector();
    const deviceInfo = deviceDetector.parse(navigator.userAgent);

    return [
        'Client: Web',
        'App Name: Private FTP Server',
        `Browser Version: ${deviceInfo.client?.name} ${deviceInfo.client?.version}`,
        `Platform: ${deviceInfo.os?.platform}`,
        `OS: ${deviceInfo.os?.name} ${deviceInfo.os?.version}`,
        `Device: ${deviceInfo.device?.brand} ${deviceInfo.device?.model} (${deviceInfo.device?.type})`
    ].join('\n');
}