import { Error } from 'i18n/localizationText';
import yup from 'utils/yup';

const usernameRegex = /^[a-z_][a-z0-9_-]{0,30}\$?$/;
const userPasswordRegex = /^(?=.*[a-z].*)(?=.*[A-Z].*)(?=.*[0-9].*)[a-zA-Z0-9!@#$%^&*()_+={[\]}<>?]+$/;

export enum BuyServerStage {
  CoreInformation,
  FtpServerInfo
}

export interface FtpCheckoutViewModel {
  username: string;
  password: string;
  passwordConfirmation: string;
}

export var ftpCheckoutViewModelSchema : yup.SchemaOf<FtpCheckoutViewModel> = yup.object({
  username: yup.string().required().max(31).matches(usernameRegex, Error.FtpUsername),
  password: yup.string().required().min(8).max(72).matches(userPasswordRegex, Error.FtpPassword),
  passwordConfirmation: yup.string().required().oneOf([yup.ref("password"), null], Error.PasswordsNotMatch)
}).required();

export interface BuyServerViewModel {
  name: string;
  diskSize: string;
  duration: string;
  isStaticIp: boolean;
}

export var buyServerViewModelSchema : yup.SchemaOf<BuyServerViewModel> = yup.object({
  name: yup.string().required().max(64).matches(/^\S+.*\S+$/, Error.InvalidField),
  diskSize: yup.string().required().matches(/\d+/),
  duration: yup.string().required().matches(/(\d+\.)?(\d{2}):(\d{2}):(\d{2})/),
  isStaticIp: yup.boolean().required()
}).required();

export interface RenameServerViewModel {
  newName: string;
}

export var renameServerViewModelSchema : yup.SchemaOf<RenameServerViewModel> = yup.object({
  newName: yup.string().required().max(64).matches(/^\S+.*\S+$/, Error.InvalidField)
}).required();

export interface CreateUserViewModel {
  username: string;
  password: string;
  passwordConfirmation: string;
}

export var createUserViewModelSchema : yup.SchemaOf<CreateUserViewModel> = yup.object({
  username: yup.string().required().max(31).matches(usernameRegex, Error.FtpUsername),
  password: yup.string().required().min(8).max(72).matches(userPasswordRegex, Error.FtpPassword),
  passwordConfirmation: yup.string().required().oneOf([yup.ref("password"), null], Error.PasswordsNotMatch)
});

export interface ChangeUserPasswordViewModel {
  password: string;
  passwordConfirmation: string;
}

export var changeUserPasswordViewModelSchema : yup.SchemaOf<ChangeUserPasswordViewModel> = yup.object({
  password: yup.string().required().min(8).max(72).matches(userPasswordRegex, Error.FtpPassword),
  passwordConfirmation: yup.string().required().oneOf([yup.ref("password"), null], Error.PasswordsNotMatch)
});