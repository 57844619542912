import { LocalizationText, Error } from './localizationText';
import en from './resources/en.json';
import zh_cn from './resources/zh_cn.json';
import zh_hk from './resources/zh_hk.json';
import fr from './resources/fr.json';
import de from './resources/de.json';
import hi from './resources/hi.json';
import it from './resources/it.json';
import ja from './resources/ja.json';
import ko from './resources/ko.json';
import pt from './resources/pt.json';
import pl from './resources/pl.json';
import ru from './resources/ru.json';
import es from './resources/es.json';
import uk from './resources/uk.json';
import { LocaleObject } from 'yup/lib/locale';

export enum Language {
  English = "en",
  ChineseSimplified = "zh-cn",
  ChineseTraditional = "zh-hk",
  French = "fr",
  German = "de",
  Hindi = "hi",
  Italian = "it",
  Japanese = "ja",
  Korean = "ko",
  Polish = "pl",
  Portuguese = "pt",
  Russian = "ru",
  Spanish = "es",
  Ukrainian = "uk",
}

export const defaultLanguage = Language.English;

export const LanguagePublicName = {
  [Language.English]: "English",
  [Language.ChineseSimplified]: "中文 - 中国",
  [Language.ChineseTraditional]: "中文 - 香港特別行政區",
  [Language.French]: "Français",
  [Language.German]: "Deutsch",
  [Language.Hindi]: "हिन्दी",
  [Language.Italian]: "Italiano",
  [Language.Japanese]: "日本語",
  [Language.Korean]: "한국어",
  [Language.Polish]: "Polski",
  [Language.Portuguese]: "Português",
  [Language.Russian]: "Русский",
  [Language.Spanish]: "Español",
  [Language.Ukrainian]: "Українська"
}

export interface Localizations {
  [Language.English]: Record<LocalizationText, string>,
  [Language.ChineseSimplified]: Record<LocalizationText, string>,
  [Language.ChineseTraditional]: Record<LocalizationText, string>,
  [Language.French]: Record<LocalizationText, string>,
  [Language.German]: Record<LocalizationText, string>,
  [Language.Hindi]: Record<LocalizationText, string>,
  [Language.Italian]: Record<LocalizationText, string>,
  [Language.Japanese]: Record<LocalizationText, string>,
  [Language.Korean]: Record<LocalizationText, string>,
  [Language.Polish]: Record<LocalizationText, string>,
  [Language.Portuguese]: Record<LocalizationText, string>,
  [Language.Russian]: Record<LocalizationText, string>,
  [Language.Spanish]: Record<LocalizationText, string>,
  [Language.Ukrainian]: Record<LocalizationText, string>
}

export const localizationMessages: Localizations = {
  en,
  "zh-cn": zh_cn,
  "zh-hk": zh_hk,
  fr,
  de,
  hi,
  it,
  ja,
  ko,
  pl,
  pt,
  ru,
  es,
  uk
};

export var getLocalizationMessages = (lang: Language) => {
  return localizationMessages[lang];
}