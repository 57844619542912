import { yupResolver } from "@hookform/resolvers/yup";
import { ClientType } from "models/common";
import {
  CreateFtpMachineAdditionalData,
  CreateMachineStripe,
  PaymentInformation
} from "models/purchaseModels";
import {
  FtpCheckoutViewModel,
  ftpCheckoutViewModelSchema
} from "models/viewModels";
import { MachineShortInfo } from "models/virtualMachineModels";
import { ROOT } from "navigation/routes";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/store";
import { getStripeCheckoutLink } from "services/paymentService";
import { createMachineStripe } from "services/purchaseService";
import CheckoutView from "./CheckoutView";

export function CheckoutContainer() {
  let coreState = useAppSelector((x) => x.core);
  const location = useLocation();
  const navigate = useNavigate();
  if (!location.state) {
    navigate(ROOT);
  }

  let {
    info,
    paymentInfo,
  }: { info: MachineShortInfo; paymentInfo: PaymentInformation } =
    location.state;

  const form = useForm<FtpCheckoutViewModel>({
    defaultValues: {
      username: "",
      password: "",
      passwordConfirmation: "",
    },
    resolver: yupResolver(ftpCheckoutViewModelSchema),
  });

  let onCheckoutSubmit = (viewModel: FtpCheckoutViewModel) => {
    if (coreState.token) {
      const additionalData: CreateFtpMachineAdditionalData = {
        Username: viewModel.username,
        Password: viewModel.password,
      };
      const model: CreateMachineStripe = {
        publicName: info.name,
        isStaticIp: info.isStaticIp,
        paymentInfo,
        additionalInfo: additionalData,
        client: ClientType.Web
      };
      createMachineStripe(coreState.token, model)
        .then((sessionId) => {
          const link = getStripeCheckoutLink(sessionId);
          window.location.replace(link);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <FormProvider {...form}>
      <CheckoutView
        isUserAuthorized={Boolean(coreState.token)}
        onCheckoutSubmit={onCheckoutSubmit}
      />
    </FormProvider>
  );
}
