import { coreApiBaseUriNoVersioning, stripeAxios } from "./apiService";

export var getStripePrice = async (publicKey: string, priceId: string) => {
  try {
    let response = await stripeAxios(publicKey).get(`/v1/prices/${priceId}`);
    let price = (response.data["unit_amount"] || 0) / 100;
    return price;
  } catch (error: unknown) {
    throw error;
  }
};

export var getStripeCheckoutLink = (sessionId: string) =>
  new URL(`stripe/checkout/${sessionId}`, coreApiBaseUriNoVersioning);
