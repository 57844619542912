import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import IconButton from "components/IconButton";
import IconUserCircle from "components/Icons/IconUserCircle";
import LoginModal from "components/LoginModal";
import StyledButton, { StyledButtonType } from "components/StyledButton";
import {
  Dashboard,
  Error as ErrorLocalizations,
  General
} from "i18n/localizationText";
import { AuthorizationProvider, RequestState } from "models/common";
import { GoogleSuccessfulAuthResponse } from "models/userModels";
import { DASHBOARD_HOME_ABSOLUTE, ROOT } from "navigation/routes";
import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Image,
  OverlayTrigger,
  Popover,
  Row,
  Stack
} from "react-bootstrap";
import { useGoogleLogin } from "react-google-login";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { authorizeAsync, getUserInfoAsync, logout } from "redux/coreSlice";
import { setSearchText } from "redux/dashboardSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  facebookDialogParams,
  facebookLoginOptions,
  getGoogleConfig,
  redirectToMicrosoftAuth
} from "services/authService";
import { useDebouncedCallback } from "use-debounce";
import { getBase64ImageSrc } from "utils/imageHelpers";
import "./Header.css";

type HeaderProps = {
  showSearch?: boolean;
  showDashboardButton?: boolean;
};

const Header = (props: HeaderProps) => {
  const { showSearch, showDashboardButton } = props;
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [searchTextInternal, setSearchTextInternal] = useState("");

  const state = useAppSelector((x) => x);
  const { core: coreState, dashboard: dashboardState } = state;
  const { token, tokenState, userInfo, userInfoState } = coreState;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const intl = useIntl();
  const messages = defineMessages({
    searchPlaceholder: {
      id: Dashboard.Search,
    },
    authorizationError: {
      id: ErrorLocalizations.AuthorizationFailed,
    },
  });

  let googleConfig = getGoogleConfig(
    (response: GoogleSuccessfulAuthResponse) => {
      dispatch(
        authorizeAsync({
          code: response.code,
          provider: AuthorizationProvider.Google,
        })
      );
      setShowLoginModal(false);
    },
    (error: string, details: string) => {
      var message = intl.formatMessage(messages.authorizationError, {
        provider: AuthorizationProvider[AuthorizationProvider.Google],
      });
      //TODO: show toast to user
      console.error(message);
      console.error(error);
      console.error(details);
    }
  );
  const googleInstance = useGoogleLogin(googleConfig);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(''));
    }
  }, []);

  useEffect(() => {
    dispatch(getUserInfoAsync());
  }, [token]);

  const onExternalAuthClick = (provider: AuthorizationProvider) => {
    switch (provider) {
      case AuthorizationProvider.Microsoft:
        redirectToMicrosoftAuth();
        break;
      case AuthorizationProvider.Facebook:
        FacebookLoginClient.redirectToDialog(
          facebookDialogParams,
          facebookLoginOptions
        );
        break;
      case AuthorizationProvider.Google:
        googleInstance.signIn();
        break;
    }
  };

  const searchTextDebouncedCallback = useDebouncedCallback((value) => {
    dispatch(setSearchText(value));
  }, 300);

  const onSearchTextChange = (value: string) => {
    setSearchTextInternal(value);
    searchTextDebouncedCallback(value);
  };

  const logoutPopup = (
    <Popover className="logout-popup">
      <Popover.Body>
        <Stack gap={3}>
          <Stack>
            <div className="logout-title">{userInfo?.name}</div>
            <div className="logout-email">{userInfo?.accountName}</div>
          </Stack>
          <StyledButton
            className="logout-button"
            buttonType={StyledButtonType.Disabled}
            onClick={() => dispatch(logout())}
          >
            <FormattedMessage id={General.Logout} />
          </StyledButton>
        </Stack>
      </Popover.Body>
    </Popover>
  );

  const renderLoginBlock = () => {
    if (token) {
      return (
        <>
          {showDashboardButton ? (
            <StyledButton
              className="dashboard-button"
              onClick={() => navigate(DASHBOARD_HOME_ABSOLUTE)}
            >
              <FormattedMessage id={General.Dashboard} />
            </StyledButton>
          ) : (
            <></>
          )}
          <OverlayTrigger
            trigger="click"
            placement="bottom-end"
            rootClose
            overlay={logoutPopup}
          >
            <div>
              <IconButton
                variant="primary"
                className="login-user-button"
                icon={
                  Boolean(userInfo?.profilePicture) ? (
                    <Image
                      roundedCircle
                      fluid
                      src={getBase64ImageSrc(userInfo?.profilePicture || "")}
                    />
                  ) : (
                    <IconUserCircle color="#ffffff" />
                  )
                }
              />
            </div>
          </OverlayTrigger>
        </>
      );
    } else {
      return (
        <StyledButton
          className="header-login"
          onClick={() => setShowLoginModal(true)}
          disabled={
            tokenState === RequestState.Loading ||
            userInfoState === RequestState.Loading
          }
        >
          <FormattedMessage id={General.Login} />
        </StyledButton>
      );
    }
  };

  return (
    <header className="header">
      <Container className="p-0">
        <Row>
          <Col className="p-0">
            <span className="header-title" onClick={() => navigate(ROOT)}>
              <FormattedMessage id={General.ProjectName} />
            </span>
          </Col>
          <Col className="p-0 text-end">
            <Stack direction="horizontal" className="justify-content-end">
              {showSearch ? (
                <Form.Control
                  type="text"
                  className="dashboard-search-box"
                  placeholder={intl.formatMessage(messages.searchPlaceholder)}
                  maxLength={64}
                  value={searchTextInternal}
                  onChange={(e) => onSearchTextChange(e.target.value)}
                />
              ) : (
                <></>
              )}
              {renderLoginBlock()}
            </Stack>
          </Col>
        </Row>
      </Container>

      <LoginModal
        show={showLoginModal}
        onCancel={() => setShowLoginModal(false)}
        onExternalAuthClick={onExternalAuthClick}
      />
    </header>
  );
};

export default Header;
