import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultLanguage, Language } from "i18n";
import { RequestState } from "models/common";
import { AuthorizeUserModel, UserInfo } from "models/userModels";
import { authorizeByMicrosoft, authorize, getUserInfo } from "services/userService";
import { getToken, RootState } from "./store";

export interface CoreState {
  language: Language;

  token?: string;
  tokenState: RequestState;

  userInfo?: UserInfo;
  userInfoState: RequestState;
}

const initialState: CoreState = {
  language: defaultLanguage,
  tokenState: RequestState.Idle,
  userInfoState: RequestState.Idle,
};

export const authorizeAsync = createAsyncThunk(
  "core/auth",
  async (model: AuthorizeUserModel) => {
    return await authorize(model);
  }
)

export const authorizeByMicrosoftAsync = createAsyncThunk(
  "core/authMs",
  async (msToken: string) => {
    return await authorizeByMicrosoft(msToken);
  }
);

export const getUserInfoAsync = createAsyncThunk<
  UserInfo,
  void,
  { state: RootState }
>(
  "core/getUserInfo",
  async (_, api) => {
    let token = getToken(api.getState()) as string;
    return await getUserInfo(token);
  },
  {
    condition: (_, api) => Boolean(api.getState()?.core?.token),
  }
);

export const coreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
    login: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.token = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorizeByMicrosoftAsync.pending, (state) => {
        state.tokenState = RequestState.Loading;
      })
      .addCase(authorizeByMicrosoftAsync.fulfilled, (state, action) => {
        state.tokenState = RequestState.Success;
        state.token = action.payload;
      })
      .addCase(authorizeByMicrosoftAsync.rejected, (state) => {
        state.tokenState = RequestState.Failure;
      })
      .addCase(authorizeAsync.pending, (state) => {
        state.tokenState = RequestState.Loading;
      })
      .addCase(authorizeAsync.fulfilled, (state, action) => {
        state.tokenState = RequestState.Success;
        state.token = action.payload;
      })
      .addCase(authorizeAsync.rejected, (state) => {
        state.tokenState = RequestState.Failure;
      })
      .addCase(getUserInfoAsync.pending, (state) => {
        state.userInfoState = RequestState.Loading;
      })
      .addCase(getUserInfoAsync.fulfilled, (state, action) => {
        state.userInfoState = RequestState.Success;
        state.userInfo = action.payload;
      })
      .addCase(getUserInfoAsync.rejected, (state) => {
        state.userInfoState = RequestState.Failure;
      });
  },
});

export const { changeLanguage, login, logout } = coreSlice.actions;

export default coreSlice.reducer;
