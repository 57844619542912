import { Col, Modal, ModalProps, Row, Stack } from "react-bootstrap";
import { Login, General, Landing } from "i18n/localizationText";
import "./LoginModal.css";
import { FormattedMessage } from "react-intl";
import StyledButton from "components/StyledButton";
import IconMicrosoft from "components/Icons/IconMicrosoft";
import { AuthorizationProvider } from "models/common";
import IconGoogle from "components/Icons/IconGoogle";
import IconFacebook from "components/Icons/IconFacebook";

type LoginModalProps = {
  onExternalAuthClick?: (provider: AuthorizationProvider) => void;
  onCancel?: () => void;
} & ModalProps;

const LoginModal = (props: LoginModalProps) => {
  let { onExternalAuthClick, onCancel, ...modalProps } = props;

  return (
    <Modal {...modalProps} size="lg" centered className="default-button" onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title className="login-modal-title">
          <FormattedMessage id={General.Login} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
          <StyledButton className="btn-google-sign-in checkout"
          onClick={() =>
            onExternalAuthClick?.call(this, AuthorizationProvider.Google)
          }
          >
                <IconGoogle className="google-sign-in-icon" />
                <span className="google-sign-in-text">
                  <FormattedMessage
                    id={Login.SignUpWith}
                    values={{ provider: "Google" }}
                  />
                </span>
              </StyledButton>
          </Col>
          <Col>
          <StyledButton className="btn-facebook-sign-in checkout"
          onClick={() =>
            onExternalAuthClick?.call(this, AuthorizationProvider.Facebook)
          }
          >
                <IconFacebook className="facebook-sign-in-icon" />
                <span className="facebook-sign-in-text">
                  <FormattedMessage
                    id={Login.SignUpWith}
                    values={{ provider: "Facebook" }}
                  />
                </span>
              </StyledButton>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <StyledButton
              className="btn-microsoft-sign-in max-width-unset"
              onClick={() =>
                onExternalAuthClick?.call(this, AuthorizationProvider.Microsoft)
              }
            >
              <IconMicrosoft className="microsoft-sign-in-icon" />
              <span className="microsoft-sign-in-text">
                <FormattedMessage
                  id={Login.SignUpWith}
                  values={{ provider: "Microsoft" }}
                />
              </span>
            </StyledButton>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
