import { ROOT } from "navigation/routes";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <Link to={ROOT}>Home</Link>
      404: page not found!
    </>
  );
};
