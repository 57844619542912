import classNames from "classnames";
import { Button, ButtonProps } from "react-bootstrap";
import "./StyledButton.css";

export enum StyledButtonType {
  Default = "button-default",
  Disabled = "button-disabled",
  Clear = "button-clear",
}

type StyledButtonProps = {
  buttonType?: StyledButtonType;
  loading?: boolean;
} & ButtonProps;

const StyledButton = (props: StyledButtonProps) => {
  let { buttonType, loading, ...buttonProps } = props;
  buttonType = buttonType || StyledButtonType.Default;

  return (
    <Button
      {...buttonProps}
      className={classNames("button", buttonType, props.className)}
    >
      {
        loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      }
      {props.children}
    </Button>
  );
};

export default StyledButton;
