import { ClientType, RequestState } from "models/common";
import { ChangeSubscriptionStatusModel } from "models/dashboardModels";
import { PaymentInformation } from "models/purchaseModels";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  cancelSubscriptionAsync,
  changeSubscriptionPauseStatusAsync,
  getVirtualMachinesAsync,
  selectVirtualMachine
} from "redux/dashboardSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getStripeCheckoutLink } from "services/paymentService";
import { cancelSubscriptionStripe, changeSubscriptionPauseStatusStripe, renewMachineStripe } from "services/purchaseService";
import { changeMachineName } from "services/virtualMachineService";
import DashboardHomeView from "./DashboardHomeView";

export function DashboardHomeContainer() {
  const state = useAppSelector((x) => x);
  const { core: coreState, dashboard: dashboardState } = state;
  const dispatch = useAppDispatch();
  const pathParameters = useParams() as { machineId: string };

  useEffect(() => {
    if (!dashboardState.virtualMachines && dashboardState.virtualMachinesState != RequestState.Loading) {
      dispatch(getVirtualMachinesAsync());
    }
  }, []);

  useEffect(() => {
    if (dashboardState.virtualMachines) {
      let machine = Boolean(pathParameters.machineId)
        ? dashboardState.virtualMachines.find((x) => x.id === pathParameters.machineId)
        : dashboardState.virtualMachines.find(() => true);
      if (machine) {
        dispatch(selectVirtualMachine(machine));
      }
    }
  }, [dashboardState.virtualMachines]);

  let onMachineSelect = (machineId: string) => {
    if (dashboardState.virtualMachines) {
      let machine = dashboardState.virtualMachines.find((x) => x.id === machineId);
      if (machine) {
        dispatch(selectVirtualMachine(machine));
      }
    }
  };

  let onPause = (machineId: string, status: boolean) => {
    let model: ChangeSubscriptionStatusModel = {
      machineId,
      status
    };
    dispatch(changeSubscriptionPauseStatusAsync(model));
  };

  let onCancel = (machineId: string) => {
    dispatch(cancelSubscriptionAsync(machineId));
  };

  let onRefreshMachines = () => {
    if (coreState.token) {
      dispatch(getVirtualMachinesAsync());
    }
  };

  let onMachineRenewSubmit = (machineId: string, paymentInfo: PaymentInformation) => {
    if (coreState?.token) {
      const model = {
        machineId: machineId,
        client: ClientType.Web,
        ...paymentInfo
      };
      renewMachineStripe(coreState.token, model)
        .then((sessionId) => {
          const link = getStripeCheckoutLink(sessionId);
          window.location.replace(link);
        })
        .catch((err) => console.log(err));
    }
  }

  let onMachineRenameSubmit = (machineId: string, newName: string) => {
    if (coreState?.token) {
      changeMachineName(coreState.token, machineId, newName)
        .then(() => {
          dispatch(getVirtualMachinesAsync());
        })
        .catch((err) => console.log(err));
    }
  };

  let virtualMachines = dashboardState.virtualMachines || [];
  if (dashboardState.searchText) {
    virtualMachines = virtualMachines.filter(x => x.publicName.toLowerCase().includes(dashboardState.searchText.toLowerCase()));
  }

  return (
    <>
      <DashboardHomeView
        machines={virtualMachines}
        machinesState={dashboardState.virtualMachinesState}
        subscriptionOperationState={dashboardState.subscriptionOperationState}
        selectedMachineInfo={dashboardState.selectedVirtualMachine}
        searchHighlightedText={dashboardState.searchText}
        onMachineSelect={onMachineSelect}
        onPause={onPause}
        onCancel={onCancel}
        onRefreshMachines={onRefreshMachines}
        onMachineRenewSubmit={onMachineRenewSubmit}
        onMachineRenameSubmit={onMachineRenameSubmit}
      />
    </>
  );
}
