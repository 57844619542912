import FlatCard from "components/FlatCard";
import StyledButton from "components/StyledButton";
import { Dashboard } from "i18n/localizationText";
import { RequestState } from "models/common";
import { VirtualMachineFullInfo } from "models/virtualMachineModels";
import React from "react";
import { ProgressBar, Spinner, Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { convertFtpSize } from "utils/ftpHelpers";
import "./FtpMachineInfo.css";

type FtpInfoSpaceProps = {
  machineInfo?: VirtualMachineFullInfo;
  requestState: RequestState;

  onRefresh?: () => void;
};

const FtpInfoSpace = (props: FtpInfoSpaceProps) => {
  let { machineInfo, requestState, onRefresh } = props;

  if (requestState === RequestState.Loading || !machineInfo) {
    return (
      <FlatCard className="ftp-info-space">
        <Spinner animation="border" variant="primary" />
      </FlatCard>
    );
  } else {
    let used = convertFtpSize(machineInfo.Used),
      total = convertFtpSize(machineInfo.Total),
      free = total - used;
    let progressValue = (used / total) * 100;

    return (
      <FlatCard className="ftp-info-space">
        <Stack gap={4}>
          <Stack direction="horizontal" gap={3}>
            <span className="ftp-info-title">
              <FormattedMessage id={Dashboard.FreeSpace} />
            </span>
            <div className="ms-auto">
              <FormattedMessage
                id={Dashboard.FtpSpaceUsed}
                values={{
                  b: (chunks: any) => (
                    <b className="ftp-info-title-used">{chunks}</b>
                  ),
                  value: used.toFixed(1),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id={Dashboard.FtpSpaceFree}
                values={{
                  b: (chunks: any) => (
                    <b className="ftp-info-title-free">{chunks}</b>
                  ),
                  value: free.toFixed(1),
                }}
              />
            </div>
          </Stack>
          <ProgressBar
            className="ftp-info-space-progress"
            now={progressValue}
          />
          <div className="ftp-info-space-refresh-container">
            <StyledButton
              className="w-100"
              onClick={() => onRefresh?.call(this)}
            >
              <FormattedMessage id={Dashboard.Refresh} />
            </StyledButton>
          </div>
        </Stack>
      </FlatCard>
    );
  }
};

export default FtpInfoSpace;
