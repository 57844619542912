import { RequestState } from "models/common";
import { MachineShortInfo } from "models/virtualMachineModels";
import { Col, Container, Row } from "react-bootstrap";
import "./BuyServer.css";
import Configuration from "./Configuration";
import Summary from "./Summary";

type BuyServerProps = {
  availableSizes?: string[];
  availableDurations?: string[];

  purchasesState: RequestState;

  onSummarySubmit?: (info: MachineShortInfo) => void;
};

const BuyServer = (props: BuyServerProps) => {
  let {
    availableSizes,
    availableDurations,
    purchasesState,
    onSummarySubmit
  } = props;

  return (
    <Container fluid className="p-0">
      <Row>
        <Col xs={7}>
          <Configuration
            availableSizes={availableSizes}
            availableDurations={availableDurations}
            purchasesState={purchasesState}
          />
        </Col>

        <Col xs={5}>
          <Summary
            onSummarySubmit={onSummarySubmit}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default BuyServer;
