import { RequestState } from "models/common";
import { PaymentInformation } from "models/purchaseModels";
import { MachineShortInfo } from "models/virtualMachineModels";
import { CHECKOUT } from "navigation/routes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPurchasesAsync } from "redux/purchasingSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import LandingView from "./LandingView";

export function LandingContainer() {
  const state = useAppSelector((x) => x.purchasing);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.purchases && state.purchasesState !== RequestState.Loading) {
      dispatch(getPurchasesAsync());
    }
  }, []);

  let onBuyServerSubmit = (
    info: MachineShortInfo,
    paymentInfo: PaymentInformation
  ) => {
    navigate(CHECKOUT, { state: { info, paymentInfo } });
  };

  return (
    <>
      <LandingView
        purchases={state.purchases}
        purchasesState={state.purchasesState}
        onBuyServerSubmit={onBuyServerSubmit}
      />
    </>
  );
}
