import AuthorizationRedirect from "components/AuthorizationRedirect";
import { AuthorizationProvider } from "models/common";
import {
  AUTH,
  AUTH_FACEBOOK,
  AUTH_MICROSOFT,
  CHECKOUT,
  DASHBOARD,
  DASHBOARD_ABOUT,
  DASHBOARD_HISTORY,
  DASHBOARD_HOME,
  MACHINE_ID_PARAMETER,
  ROOT
} from "navigation/routes";
import About from "pages/About";
import Checkout from "pages/Checkout";
import Dashboard from "pages/Dashboard";
import DashboardHome from "pages/DashboardHome";
import History from "pages/History";
import Landing from "pages/Landing";
import NotFound from "pages/NotFound";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./RequireAuth";

export const RouterConfig = () => {
  return (
    <Routes>
      {/* List all public routes here */}
      <Route path={ROOT} element={<Landing />} />
      <Route path={CHECKOUT} element={<Checkout />} />
      <Route path={AUTH}>
        <Route
          path={AUTH_FACEBOOK}
          element={
            <AuthorizationRedirect provider={AuthorizationProvider.Facebook} />
          }
        />
        <Route
          path={AUTH_MICROSOFT}
          element={
            <AuthorizationRedirect provider={AuthorizationProvider.Microsoft} />
          }
        />
      </Route>

      {/* List all private/auth routes here */}
      <Route path={DASHBOARD} element={<Dashboard />}>
        <Route path={DASHBOARD_HOME}>
          <Route
            path={MACHINE_ID_PARAMETER}
            element={
              <RequireAuth>
                <DashboardHome />
              </RequireAuth>
            }
          />
          <Route
            path=""
            element={
              <RequireAuth>
                <DashboardHome />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path={DASHBOARD_HISTORY}
          element={
            <RequireAuth>
              <History />
            </RequireAuth>
          }
        />
        <Route path={DASHBOARD_ABOUT} element={<About />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
