import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import coreReducer from "./coreSlice";
import purchasingReducer from "./purchasingSlice";
import dashboardReducer from "./dashboardSlice";
import historyReducer from "./historySlice";
import ftpServerReducer from "./ftpServerSlice";
import storage from "redux-persist/lib/storage";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

//const rootConfig = {
//  key: "root",
//  version: 1,
//  storage,
//  whitelist: ['core']
//};

const coreConfig = {
  key: "core",
  version: 1,
  storage,
  whitelist: ['language', 'token']
};

const rootReducer = combineReducers({
  //Add here new reducers
  core: persistReducer(coreConfig, coreReducer),
  purchasing: purchasingReducer,
  dashboard: dashboardReducer,
  ftpServer: ftpServerReducer,
  history: historyReducer
});

const persistedReducer = rootReducer;
//Use persistReducer(rootConfig, rootReducer); if more root objects persisting is required

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const getToken = (state: RootState) => state.core.token;