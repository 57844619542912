import { ROOT } from "navigation/routes";
import { Navigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "redux/store";
import { logout } from "redux/coreSlice";
import { validateToken } from "services/authService";

export interface RequireAuthProps {
  children: any;
  redirectTo: string;
}

export default function RequireAuth({
  children,
  redirectTo,
}: RequireAuthProps) {
  let coreState = useAppSelector((x) => x.core);
  let dispatch = useAppDispatch();

  let isTokenValid = false;
  if (coreState.token) {
    isTokenValid = validateToken(coreState.token);
    if (!isTokenValid){
      dispatch(logout());
    }
  }
  
  return isTokenValid ? children : <Navigate to={redirectTo} />;
}

RequireAuth.defaultProps = {
  redirectTo: ROOT,
};
