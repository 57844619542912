import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import Divider from "components/Divider";
import Header from "components/Header";
import IconArrowRight from "components/Icons/IconArrowRight";
import IconFacebook from "components/Icons/IconFacebook";
import IconGoogle from "components/Icons/IconGoogle";
import IconMicrosoft from "components/Icons/IconMicrosoft";
import StyledButton from "components/StyledButton";
import {
  Checkout,
  Error as ErrorLocalizations,
  General,
  Login
} from "i18n/localizationText";
import { AuthorizationProvider } from "models/common";
import { GoogleSuccessfulAuthResponse } from "models/userModels";
import { FtpCheckoutViewModel } from "models/viewModels";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useGoogleLogin } from "react-google-login";
import {
  useFormContext
} from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { authorizeAsync } from "redux/coreSlice";
import { useAppDispatch } from "redux/store";
import {
  facebookDialogParams,
  facebookLoginOptions,
  getGoogleConfig,
  redirectToMicrosoftAuth
} from "services/authService";
import { localizeYupError } from "utils/yup";
import "./Checkout.css";

type CheckoutViewProps = {
  isUserAuthorized: boolean;

  onCheckoutSubmit?: (viewModel: FtpCheckoutViewModel) => void;
};

const CheckoutView = (props: CheckoutViewProps) => {
  const {
    isUserAuthorized,
    onCheckoutSubmit,
  } = props;

  const { formState, register, handleSubmit } = useFormContext<FtpCheckoutViewModel>();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  let onSubmit = (data: FtpCheckoutViewModel) => {
    onCheckoutSubmit?.call(this, data);
  };

  let googleConfig = getGoogleConfig(
    (response: GoogleSuccessfulAuthResponse) => {
      dispatch(
        authorizeAsync({
          code: response.code,
          provider: AuthorizationProvider.Google,
        })
      );
    },
    (error: string, details: string) => {
      var message = intl.formatMessage(
        { id: ErrorLocalizations.AuthorizationFailed },
        {
          provider: AuthorizationProvider[AuthorizationProvider.Google],
        }
      );
      console.log(message);
    }
  );
  const googleInstance = useGoogleLogin(googleConfig);

  const onExternalAuthClick = (provider: AuthorizationProvider) => {
    switch (provider) {
      case AuthorizationProvider.Microsoft:
        redirectToMicrosoftAuth();
        break;
      case AuthorizationProvider.Facebook:
        FacebookLoginClient.redirectToDialog(
          facebookDialogParams,
          facebookLoginOptions
        );
        break;
      case AuthorizationProvider.Google:
        googleInstance.signIn();
        break;
    }
  };

  return (
    <Container className="checkout-body">
      <Header showDashboardButton />

      <Row className="text-center">
        <h1 className="checkout-main-title">
          <FormattedMessage id={Checkout.Title} />
        </h1>
        <div className="checkout-main-text">
          <FormattedMessage id={Checkout.Subtitle} />
        </div>
      </Row>

      {!isUserAuthorized && (
        <>
          <Row className="mt-4">
            <Col
              xs="auto"
              className="checkout-section-heading d-flex align-items-center p-0"
            >
              <FormattedMessage id={General.SignUp} />
            </Col>
            <Col className="d-flex align-items-center p-0">
              <Divider />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <StyledButton
                className="btn-microsoft-sign-in checkout"
                onClick={() =>
                  onExternalAuthClick(AuthorizationProvider.Microsoft)
                }
              >
                <IconMicrosoft className="microsoft-sign-in-icon" />
                <span className="microsoft-sign-in-text">
                  <FormattedMessage
                    id={Login.SignUpWith}
                    values={{ provider: "Microsoft" }}
                  />
                </span>
              </StyledButton>
            </Col>
            <Col>
              <StyledButton
                className="btn-google-sign-in checkout"
                onClick={() =>
                  onExternalAuthClick(AuthorizationProvider.Google)
                }
              >
                <IconGoogle className="google-sign-in-icon" />
                <span className="google-sign-in-text">
                  <FormattedMessage
                    id={Login.SignUpWith}
                    values={{ provider: "Google" }}
                  />
                </span>
              </StyledButton>
            </Col>
            <Col>
              <StyledButton
                className="btn-facebook-sign-in checkout"
                onClick={() =>
                  onExternalAuthClick(AuthorizationProvider.Facebook)
                }
              >
                <IconFacebook className="facebook-sign-in-icon" />
                <span className="facebook-sign-in-text">
                  <FormattedMessage
                    id={Login.SignUpWith}
                    values={{ provider: "Facebook" }}
                  />
                </span>
              </StyledButton>
            </Col>
          </Row>
        </>
      )}

      <Row className="mt-4">
        <Col
          xs="auto"
          className="checkout-section-heading d-flex align-items-center p-0"
        >
          <FormattedMessage id={Checkout.AddNewUser} />
        </Col>
        <Col className="d-flex align-items-center p-0">
          <Divider />
        </Col>
      </Row>

      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className="mt-4">
          <Form.Group as={Col} controlId="checkout.ftpLogin">
            <Form.Label>
              <FormattedMessage id={General.FtpLogin} />
            </Form.Label>
            <Form.Control
              className="default-input"
              type="text"
              isInvalid={!!formState.errors.username}
              {...register("username")}
            />
            <Form.Control.Feedback type="invalid">
              {localizeYupError(intl, formState.errors.username)}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="checkout.ftpPassword">
            <Form.Label>
              <FormattedMessage id={General.FtpPassword} />
            </Form.Label>
            <Form.Control
              className="default-input"
              type="password"
              isInvalid={!!formState.errors.password}
              {...register("password")}
            />
            <Form.Control.Feedback type="invalid">
              {localizeYupError(intl, formState.errors.password)}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mt-2">
          <Col></Col>
          <Form.Group as={Col} controlId="checkout.ftpPasswordConfirmation">
            <Form.Label>
              <FormattedMessage id={General.ConfirmPassword} />
            </Form.Label>
            <Form.Control
              className="default-input"
              type="password"
              isInvalid={!!formState.errors.passwordConfirmation}
              {...register("passwordConfirmation")}
            />
            <Form.Control.Feedback type="invalid">
              {localizeYupError(intl, formState.errors.passwordConfirmation)}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="my-4 justify-content-center">
          <StyledButton className="button-continue" type="submit">
            <FormattedMessage id={Checkout.CompleteOrder} />
            <IconArrowRight className="button-continue-arrow" color="white" />
          </StyledButton>
        </Row>
      </Form>
    </Container>
  );
};

export default CheckoutView;
