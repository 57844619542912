import ChangeUserPasswordModal from "components/ChangeUserPasswordModal";
import ConfirmationModal from "components/ConfirmationModal";
import CreateUserModal from "components/CreateUserModal";
import FlatCard from "components/FlatCard";
import StyledButton from "components/StyledButton";
import { Dashboard, General } from "i18n/localizationText";
import { RequestState } from "models/common";
import { AddUserRequestModel, ChangeBlockStateModel, ChangeUserPasswordRequestModel, RemoveUserModel } from "models/ftpModels";
import {
  VirtualMachineStatus
} from "models/virtualMachineModels";
import { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { addUserAsync, changeUserBlockStateAsync, changeUserPasswordAsync, getMachineInfoAsync, getUsersAsync, removeUserAsync } from "redux/ftpServerSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import FtpInfoSpace from "./FtpInfoSpace";
import FtpInfoUsers from "./FtpInfoUsers";
import "./FtpMachineInfo.css";

const FtpMachineInfo = () => {
  let state = useAppSelector((x) => x);
  let dispatch = useAppDispatch();

  let { selectedVirtualMachine } = state.dashboard;
  let { ftpMachineInfo, ftpMachineInfoState, ftpUsers, ftpUsersState } =
    state.ftpServer;

  const [showAddUserModal, setShowAddUserModal] = useState<boolean>();
  const [changePasswordUsername, setChangePasswordUsername] = useState<string>();
  const [deleteUserUsername, setDeleteUserUsername] = useState<string>();

  useEffect(() => {
    tryRefreshInfo();
  }, [selectedVirtualMachine]);

  useEffect(() => {
    tryRefreshUsers();
  }, [selectedVirtualMachine]);

  let tryRefreshInfo = () => {
    if (
      selectedVirtualMachine &&
      ftpMachineInfoState !== RequestState.Loading
    ) {
      dispatch(getMachineInfoAsync(selectedVirtualMachine.id));
    }
  };

  let tryRefreshUsers = () => {
    if (
      selectedVirtualMachine &&
      ftpUsersState !== RequestState.Loading
    ) {
      dispatch(getUsersAsync(selectedVirtualMachine.id));
    }
  };

  const onToggleBlock = (userName: string, isActive: boolean) => {
    if (selectedVirtualMachine){
      const model: ChangeBlockStateModel = {
        machineId: selectedVirtualMachine.id,
        user: userName,
        block: isActive
      };
      dispatch(changeUserBlockStateAsync(model));
    }
  };

  const onAddUserSubmit = (machineId: string, username: string, password: string) => {
    setShowAddUserModal(false);
    const model: AddUserRequestModel = {
      machineId,
      username,
      password
    };
    dispatch(addUserAsync(model)).then(() => {
      tryRefreshUsers();
    });
  };

  const onChangeUserPassword = (machineId: string, username: string, password: string) => {
    setChangePasswordUsername(undefined);
    const model: ChangeUserPasswordRequestModel = {
      machineId,
      username: username,
      password
    };
    dispatch(changeUserPasswordAsync(model));
  };

  const onUserDelete = () => {
    if (selectedVirtualMachine && deleteUserUsername) {
      const model: RemoveUserModel = {
        machineId: selectedVirtualMachine?.id,
        user: deleteUserUsername
      };
      dispatch(removeUserAsync(model)).then(() => {
        tryRefreshUsers();
      });
    }
    setDeleteUserUsername(undefined);
  }

  let renderProcessingPlaceholder = () => {
    return (
      <FlatCard className="ftp-info-placeholder-container">
        <Stack>
          <span className="text-center">
            <FormattedMessage id={Dashboard.FtpServerProcessingTitle} />
          </span>
          <span className="text-center">
            <FormattedMessage id={Dashboard.FtpServerProcessingSubtitle} />
          </span>
          <StyledButton 
            onClick={tryRefreshInfo} 
            className="mt-2 mx-auto"
            disabled={ftpMachineInfoState === RequestState.Loading}
            loading={ftpMachineInfoState === RequestState.Loading}
          >
            <FormattedMessage id={Dashboard.Refresh} />
          </StyledButton>
        </Stack>
      </FlatCard>
    );
  };

  if (!selectedVirtualMachine) {
    return <></>;
  } else {
    if (selectedVirtualMachine?.status === VirtualMachineStatus.Creating) {
      return renderProcessingPlaceholder();
    } else {
      return (
        <div>
          <Row>
            <Col>
              <FtpInfoSpace
                machineInfo={ftpMachineInfo}
                requestState={ftpMachineInfoState}
                onRefresh={tryRefreshInfo}
              />
            </Col>
            <Col>
              <FtpInfoUsers
                users={ftpUsers}
                requestState={ftpUsersState}
                onToggleBlock={onToggleBlock}
                onAddUser={() => setShowAddUserModal(true)}
                onChangePassword={(user: string) => setChangePasswordUsername(user)}
                onDeleteUser={(user: string) => setDeleteUserUsername(user)}
                onRefresh={tryRefreshUsers}
              />
            </Col>
          </Row>

          <CreateUserModal 
            show={showAddUserModal}
            machineId={selectedVirtualMachine.id}
            onSubmit={onAddUserSubmit}
            onClose={() => setShowAddUserModal(false)}
            onHide={() => setShowAddUserModal(false)}
          />
      
          <ChangeUserPasswordModal
            show={Boolean(changePasswordUsername)}
            machineId={selectedVirtualMachine.id}
            username={changePasswordUsername}
            onSubmit={onChangeUserPassword}
            onClose={() => setChangePasswordUsername(undefined)}
            onHide={() => setChangePasswordUsername(undefined)}
          />

          <ConfirmationModal
            showCross showCloseButton
            show={Boolean(deleteUserUsername)}
            titleTextId={Dashboard.DeleteUser}
            descriptionTextId={Dashboard.DeleteUserModalDescription}
            descriptionTextValues={{ user: deleteUserUsername }}
            submitTextId={General.Delete}
            cancelTextId={General.Cancel}
            onSubmit={onUserDelete}
            onHide={() => setDeleteUserUsername(undefined)}
          />
        </div>
      );
    }
  }
};

export default FtpMachineInfo;
