import { AxiosError } from "axios";
import { RequestError } from "models/common";
import {
  VirtualMachineFullModel,
  VirtualMachineShortModel
} from "models/virtualMachineModels";
import {
  authenticatedCoreAxios,
  handleAxiosError
} from "./apiService";

export const machines = "machines";
const machinesEndpoint = (subRoute: string): string => machines + subRoute;

export var getAllMachines = (token: string, area: string) =>
  new Promise<VirtualMachineShortModel[]>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .get<VirtualMachineShortModel[]>(machinesEndpoint("/getAll"), {
        params: {
          area: area
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var getMachineInfo = (token: string, machineId: string) =>
  new Promise<VirtualMachineFullModel>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .get<VirtualMachineFullModel>(machinesEndpoint("/getInfo"), {
        params: { machineId: machineId },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var changeMachineName = (
  token: string,
  machineId: string,
  newName: string
) =>
  new Promise<any>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .put(machinesEndpoint("/changeName"), {
        machineId: machineId,
        newName: newName,
      })
      .then(() => {
        resolve(null);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });
