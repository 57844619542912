import { yupResolver } from "@hookform/resolvers/yup";
import { ClientType, RequestState } from "models/common";
import {
  CreateFtpMachineAdditionalData,
  CreateMachineStripe
} from "models/purchaseModels";
import {
  BuyServerStage,
  BuyServerViewModel,
  buyServerViewModelSchema,
  FtpCheckoutViewModel,
  ftpCheckoutViewModelSchema
} from "models/viewModels";
import { useEffect, useState } from "react";
import { ModalProps } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { getPurchasesAsync } from "redux/purchasingSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getStripeCheckoutLink, getStripePrice } from "services/paymentService";
import { createMachineStripe } from "services/purchaseService";
import { getAppropriatePurchase } from "utils/purchaseHelpers";
import "./BuyServerModal.css";
import BuyServerModalView from "./BuyServerModalView";

type BuyServerModalContainerProps = {
  onClose?: () => void;
} & ModalProps;

export const BuyServerModalContainer = (props: BuyServerModalContainerProps) => {
  const { onClose, ...modalProps } = props;
  const [totalPrice, setTotalPrice] = useState(0);
  const [coreInformation, setCoreInformation] = useState<BuyServerViewModel>();
  const [stage, setStage] = useState<BuyServerStage>(BuyServerStage.CoreInformation);

  const state = useAppSelector((x) => x);
  const { core: coreState, purchasing: purchasingState } = state;
  var purchases = purchasingState.purchases;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!purchases && purchasingState.purchasesState !== RequestState.Loading) {
      dispatch(getPurchasesAsync());
    }
  }, []);

  let availableSizes =
    purchasingState.purchases?.map(
      (purchase) => purchase.additionalData.Size
    ) || [];
  let plans = purchases?.flatMap((purchase) => purchase.plans) || [];
  let availableDurations = plans.map((plan) => plan.duration);

  let defaultSize = availableSizes.find(() => true);

  const coreInfoForm = useForm<BuyServerViewModel>({
    mode: "all",
    defaultValues: {
      name: "",
      diskSize: defaultSize,
      duration: availableDurations.find(() => true),
      isStaticIp: true
    },
    resolver: yupResolver(buyServerViewModelSchema),
  });

  const ftpInfoForm = useForm<FtpCheckoutViewModel>({
    mode: "all",
    defaultValues: {
      username: "",
      password: "",
      passwordConfirmation: "",
    },
    resolver: yupResolver(ftpCheckoutViewModelSchema),
  });

  var formFieldsState = coreInfoForm.watch();
  useEffect(() => {
    let paymentInfo = getAppropriatePurchase(purchases || [], formFieldsState);
    if (purchasingState.stripeKey && paymentInfo?.additionalId) {
      getStripePrice(purchasingState.stripeKey, paymentInfo.additionalId)
        .then((price: number) => setTotalPrice(price))
        .catch((err) => console.log(err));
    }
  }, [formFieldsState.duration, formFieldsState.diskSize]);

  let onPaymentInfoSubmit = (info: BuyServerViewModel) => {
    setCoreInformation(info);
    setStage(BuyServerStage.FtpServerInfo);
  };

  let onFtpInfoSubmit = (info: FtpCheckoutViewModel) => {
    if (coreInformation) {
      let paymentInfo = getAppropriatePurchase(purchases || [], coreInformation);
      if (coreState.token && paymentInfo) {
        const additionalData: CreateFtpMachineAdditionalData = {
          Username: info.username,
          Password: info.password,
        };
        const model: CreateMachineStripe = {
          publicName: coreInformation.name,
          isStaticIp: coreInformation.isStaticIp,
          paymentInfo,
          additionalInfo: additionalData,
          client: ClientType.Web
        };
        createMachineStripe(coreState.token, model)
          .then((sessionId) => {
            const link = getStripeCheckoutLink(sessionId);
            window.location.replace(link);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  let onBack = () => {
    setStage(BuyServerStage.CoreInformation);
  };

  return (
    <BuyServerModalView
      {...modalProps}
      availableSizes={availableSizes}
      availableDurations={availableDurations}
      total={totalPrice}
      stage={stage}
      coreForm={coreInfoForm}
      ftpForm={ftpInfoForm}
      onPaymentInfoSubmit={onPaymentInfoSubmit}
      onFtpInfoSubmit={onFtpInfoSubmit}
      onClose={onClose}
      onBack={onBack}
    />
  );
};