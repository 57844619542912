import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import IconButton from "components/IconButton";
import IconCross from "components/Icons/IconCross";
import StyledButton from "components/StyledButton";
import { Dashboard, General } from "i18n/localizationText";
import { ChangeUserPasswordViewModel, changeUserPasswordViewModelSchema } from "models/viewModels";
import { Form, Modal, ModalProps, Row, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { localizeYupError } from "utils/yup";
import "./ChangeUserPasswordModal.css";

type ChangeUserPasswordModalProps = {
  machineId?: string;
  username?: string;

  onSubmit: (machineId: string, username: string, password: string) => void;
  onClose?: () => void;
} & ModalProps;

const ChangeUserPasswordModal = (props: ChangeUserPasswordModalProps) => {
  const { machineId, username, onSubmit, onClose, ...modalProps } = props;
  const intl = useIntl();

  const { register, handleSubmit, formState, reset } = useForm<ChangeUserPasswordViewModel>({
    mode: "onBlur",
    resolver: yupResolver(changeUserPasswordViewModelSchema),
  });

  let onModalSubmit = (viewModel: ChangeUserPasswordViewModel) => {
    if (machineId && username && onSubmit) {
      reset({
        password: '',
        passwordConfirmation: ''
      });
      onSubmit.call(this, machineId, username, viewModel.password);
    }
  }

  return (
    <Modal
      {...modalProps}
      centered
      className={classNames(modalProps.className, "dialog-container")}
    >
      <IconButton
        clear
        icon={<IconCross color="#212529" />}
        className="dialog-close-button"
        onClick={() => onClose?.call(this)}
      />

      <Modal.Body>
        <Stack gap={3}>
          <Stack>
            <div className="dialog-header">
              <FormattedMessage id={Dashboard.ChangeUserPassword} />
            </div>
          </Stack>

          <Form.Group controlId="changeUserPassword.username">
            <Form.Label>
              <FormattedMessage id={General.User} />
            </Form.Label>
            <Form.Control
              readOnly
              className="default-input"
              type="text"
              placeholder={username}
            />
          </Form.Group>

          <Form.Group controlId="changeUserPassword.password">
            <Form.Label>
              <FormattedMessage id={General.Password} />
            </Form.Label>
            <Form.Control
              className="default-input"
              type="password"
              isInvalid={!!formState.errors.password}
              {...register("password")}
            />
            <Form.Control.Feedback type="invalid">
              {localizeYupError(intl, formState.errors.password)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="changeUserPassword.passwordConfirmation">
            <Form.Label>
              <FormattedMessage id={General.ConfirmPassword} />
            </Form.Label>
            <Form.Control
              className="default-input"
              type="password"
              isInvalid={!!formState.errors.passwordConfirmation}
              {...register("passwordConfirmation")}
            />
            <Form.Control.Feedback type="invalid">
              {localizeYupError(intl, formState.errors.passwordConfirmation)}
            </Form.Control.Feedback>
          </Form.Group>

          <Row className="justify-content-center">
            <StyledButton
              className="w-100"
              onClick={handleSubmit(onModalSubmit)}
            >
              <FormattedMessage id={General.Save} />
            </StyledButton>
          </Row>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeUserPasswordModal;
