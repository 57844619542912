import IconButton from "components/IconButton";
import IconDelete from "components/Icons/IconDelete";
import IconKey from "components/Icons/IconKey";
import IconLock from "components/Icons/IconLock";
import IconUnlock from "components/Icons/IconUnlock";
import IconUserCircle from "components/Icons/IconUserCircle";
import { Dashboard } from "i18n/localizationText";
import { FtpUser } from "models/ftpModels";
import { Stack } from "react-bootstrap";
import { defineMessages, useIntl } from "react-intl";
import "./FtpMachineInfo.css";

type FtpUserItemProps = {
  user: FtpUser;

  onToggleBlock?: (user: FtpUser) => void;
  onChangePassword?: (user: FtpUser) => void;
  onDelete?: (user: FtpUser) => void;
};

const FtpUserItem = (props: FtpUserItemProps) => {
  let { user, onToggleBlock, onChangePassword, onDelete } = props;
  const intl = useIntl();
  const messages = defineMessages({
    lockTooltip: {
      id: Dashboard.LockUser,
    },
    unlockTooltip: {
      id: Dashboard.UnlockUser,
    },
    changePasswordTooltip: {
      id: Dashboard.ChangeUserPassword,
    },
    deleteTooltip: {
      id: Dashboard.DeleteUser,
    }
  });

  return (
    <Stack direction="horizontal" gap={2}>
      <IconUserCircle width="32" color="#BEBEBE" />
      <span>{user.username}</span>
      {
        user.isActive ? (
          <IconButton
            clear
            className="ms-auto"
            icon={<IconUnlock color="#212529" />}
            title={intl.formatMessage(messages.unlockTooltip)}
            onClick={() => onToggleBlock?.call(this, user)}
          />
        ) : (
          <IconButton
            clear
            className="ms-auto"
            icon={<IconLock color="#212529" />}
            title={intl.formatMessage(messages.lockTooltip)}
            onClick={() => onToggleBlock?.call(this, user)}
          />
        )
      }

      <IconButton
        clear
        icon={<IconKey color="#212529" />}
        title={intl.formatMessage(messages.changePasswordTooltip)}
        onClick={() => onChangePassword?.call(this, user)}
      />
      <IconButton
        clear
        icon={<IconDelete color="#212529" />}
        title={intl.formatMessage(messages.deleteTooltip)}
        onClick={() => onDelete?.call(this, user)}
      />
    </Stack>
  );
};

export default FtpUserItem;
