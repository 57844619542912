import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "components/Layout";
import {
  defaultLanguage,
  getLocalizationMessages
} from "i18n";
import { RouterConfig } from "navigation/RouterConfig";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { useAppSelector } from "redux/store";
import "./App.css";

const App = () => {
  let coreState = useAppSelector((x) => x.core);
  let localizationMessages = getLocalizationMessages(coreState.language);

  return (
    <IntlProvider
      defaultLocale={defaultLanguage}
      key={coreState.language}
      locale={coreState.language}
      messages={localizationMessages}
    >
      <BrowserRouter>
        <Layout>
          <RouterConfig />
        </Layout>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default App;
