import FlatCard from "components/FlatCard";
import IconButton from "components/IconButton";
import IconPlus from "components/Icons/IconPlus";
import IconRefresh from "components/Icons/IconRefresh";
import { Checkout, Dashboard } from "i18n/localizationText";
import { RequestState } from "models/common";
import { FtpUser } from "models/ftpModels";
import { Spinner, Stack } from "react-bootstrap";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import "./FtpMachineInfo.css";
import FtpUserItem from "./FtpUserItem";

type FtpInfoUsersProps = {
  users?: FtpUser[];
  requestState: RequestState;

  onToggleBlock?: (username: string, isActive: boolean) => void;
  onAddUser?: () => void;
  onChangePassword?: (username: string) => void;
  onDeleteUser?: (username: string) => void;
  onRefresh?: () => void;
};

const FtpInfoUsers = (props: FtpInfoUsersProps) => {
  let { users, requestState, onToggleBlock, onAddUser, onChangePassword, onDeleteUser, onRefresh } = props;
  const intl = useIntl();
  const messages = defineMessages({
    refreshTooltip: {
      id: Dashboard.Refresh
    },
    addUserTooltip: {
      id: Checkout.AddNewUser
    }
  });

  const onToggleBlockPressed = (user: FtpUser) => {
    onToggleBlock?.call(this, user.username, !user.isActive);
  };

  const onChangePasswordPressed = (user: FtpUser) => {
    onChangePassword?.call(this, user.username);
  };

  const onDeleteUserPressed = (user: FtpUser) => {
    onDeleteUser?.call(this, user.username);
  };

  if (requestState === RequestState.Loading || !users) {
    return (
      <FlatCard className="ftp-info-users">
        <Spinner animation="border" variant="primary" />
      </FlatCard>
    );
  } else {
    return (
      <FlatCard className="ftp-info-users">
        <Stack gap={2}>
          <Stack direction="horizontal">
            <span className="ftp-info-title">
              <FormattedMessage
                id={Dashboard.UsersTitle}
                values={{ count: users.length }}
              />
            </span>
            <IconButton
              className="ms-auto"
              clear
              icon={<IconRefresh height="20" />}
              title={intl.formatMessage(messages.refreshTooltip)}
              onClick={() => onRefresh?.call(this)}
            />
            <IconButton
              clear
              icon={<IconPlus color="#000000" />}
              title={intl.formatMessage(messages.addUserTooltip)}
              onClick={() => onAddUser?.call(this)}
            />
          </Stack>
          {users?.map((user) => (
            <FtpUserItem
              key={user.username}
              user={user}
              onToggleBlock={onToggleBlockPressed}
              onChangePassword={onChangePasswordPressed}
              onDelete={onDeleteUserPressed}
            />
          ))}
        </Stack>
      </FlatCard>
    );
  }
};

export default FtpInfoUsers;
