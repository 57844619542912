import { Language } from "i18n";
import { General } from "i18n/localizationText";
import { guideUrl } from "models/constants";
import { DASHBOARD_ABOUT_ABSOLUTE } from "navigation/routes";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { changeLanguage } from "redux/coreSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import { createContactUsLink } from "utils/urlHelpers";
import LanguagePicker from "../LanguagePicker";
import "./Footer.css";

const Footer = () => {
  let selector = useAppSelector((x) => x.core);
  let dispatch = useAppDispatch();

  let handleOnLanguageChange = (language: Language) => {
    dispatch(changeLanguage(language));
  };

  return (
    <footer>
      <Container>
        <Row>
          <Col xs={7} className="d-flex p-0">
            <Stack className="footer-links" direction="horizontal" gap={4}>
              <a
                href="#"
                onClick={() => window.open(createContactUsLink(), '_self')}
              >
                <FormattedMessage id={General.ContactUs} />
              </a>
              <Link to={DASHBOARD_ABOUT_ABSOLUTE}>
                <FormattedMessage id={General.About} />
              </Link>
              <a
                href="http://tidalmediainc.com/license-terms/"
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id={General.TermsOfUse} />
              </a>
              <a
                href="http://tidalmediainc.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id={General.Privacy} />
              </a>
              <a
                href={guideUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id={General.Guide} />
              </a>
            </Stack>
          </Col>
          <Col xs={5} className="p-0">
            <LanguagePicker
              currentLanguage={selector.language}
              onLanguageChange={handleOnLanguageChange}
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
