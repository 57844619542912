import BuyServerModal from "components/BuyServerModal";
import ConfirmationModal from "components/ConfirmationModal";
import FlatCard from "components/FlatCard";
import IconButton from "components/IconButton";
import IconPlus from "components/Icons/IconPlus";
import IconRefresh from "components/Icons/IconRefresh";
import RenameServerModal from "components/RenameServerModal";
import RenewServerModal from "components/RenewServerModal";
import StyledButton from "components/StyledButton";
import { Dashboard, General } from "i18n/localizationText";
import { RequestState } from "models/common";
import { RenameMachineData } from "models/dashboardModels";
import {
  PaymentInformation
} from "models/purchaseModels";
import {
  VirtualMachineShortModel
} from "models/virtualMachineModels";
import { useState } from "react";
import { Container, Spinner, Stack } from "react-bootstrap";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import FtpMachineInfo from "./components/FtpMachineInfo";
import MachineEntry from "./components/MachineEntry";
import MachineShortInfo from "./components/MachineShortInfo";
import "./DashboardHome.css";

type DashboardHomeViewProps = {
  machinesState: RequestState;
  machines?: VirtualMachineShortModel[];
  subscriptionOperationState: RequestState;

  selectedMachineInfo?: VirtualMachineShortModel;
  searchHighlightedText?: string;

  onMachineSelect: (machineId: string) => void;
  onPause: (machineId: string, status: boolean) => void;
  onCancel: (machineId: string) => void;
  onRefreshMachines: () => void;
  onMachineRenewSubmit: (machineId: string, paymentInfo: PaymentInformation) => void;
  onMachineRenameSubmit: (machineId: string, newName: string) => void;
};

const DashboardHomeView = (props: DashboardHomeViewProps) => {
  const {
    machines,
    machinesState,
    subscriptionOperationState,
    selectedMachineInfo,
    searchHighlightedText,
    onMachineSelect,
    onPause,
    onCancel,
    onRefreshMachines,
    onMachineRenewSubmit,
    onMachineRenameSubmit
  } = props;
  const [renewMachineId, setRenewMachineId] = useState<string>();
  const [showNewMachineModal, setShowNewMachineModal] = useState<boolean>();
  const [renameMachineData, setRenameMachineData] = useState<RenameMachineData>();
  const intl = useIntl();
  const messages = defineMessages({
    refreshTooltip: {
      id: Dashboard.Refresh,
    }
  });

  let renderMachineInfo = () => {
    if (selectedMachineInfo) {
      return (
        <MachineShortInfo
          machine={selectedMachineInfo}
          subscriptionOperationState={subscriptionOperationState}
          onRenewClick={setRenewMachineId}
          onPauseClick={onPause}
          onCancelClick={onCancel}
        />
      );
    } else {
      return <></>;
    }
  };

  let renderMachineList = () => {
    if (machinesState !== RequestState.Loading) {
      return (
        <Stack gap={2}>
          {machines?.map((machine) => (
            <MachineEntry
              key={machine.id}
              machine={machine}
              isSelected={selectedMachineInfo?.id === machine.id}
              highlightText={searchHighlightedText}
              onSelect={(machine: VirtualMachineShortModel) =>
                onMachineSelect(machine.id)
              }
              onRenewClick={(machine) => setRenewMachineId(machine.id)}
              onEditClick={(machine) => {
                setRenameMachineData({
                  machineId: machine.id,
                  currentMachineName: machine.publicName
                });
              }}
            />
          ))}
        </Stack>
      );
    } else {
      return <Spinner animation="border" />;
    }
  };

  let onMachineRenameSubmitView = (machineId: string, newName: string) => {
    setRenameMachineData(undefined);
    onMachineRenameSubmit.call(this, machineId, newName);
  };

  return (
    <Container fluid className="dashboard-home-container">
      <Stack gap={Boolean(selectedMachineInfo) ? 4 : 0}>
        <FtpMachineInfo />

        {renderMachineInfo()}

        <FlatCard className="dashboard-machine-list-container">
          <Stack gap={3}>
            <div className="d-flex justify-content-between">
              <Stack
                className="align-items-start"
                direction="horizontal"
                gap={3}
              >
                <div className="dashboard-machine-list-title">
                  <FormattedMessage id={Dashboard.YourFtpServers} />
                </div>
                <IconButton
                  icon={<IconRefresh />}
                  clear
                  title={intl.formatMessage(messages.refreshTooltip)}
                  onClick={() => onRefreshMachines?.call(this)}
                />
              </Stack>

              <StyledButton
                className="w-100"
                onClick={() => setShowNewMachineModal(true)}
              >
                <IconPlus />
                <FormattedMessage id={General.New} />
              </StyledButton>
            </div>
            {renderMachineList()}
          </Stack>
        </FlatCard>
      </Stack>

      <BuyServerModal
        show={showNewMachineModal}
        onClose={() => setShowNewMachineModal(false)}
        onHide={() => setShowNewMachineModal(false)}
      />

      <RenewServerModal
        show={Boolean(renewMachineId)}
        machineId={renewMachineId}
        onSubmit={onMachineRenewSubmit}
        onClose={() => setRenewMachineId(undefined)}
        onHide={() => setRenewMachineId(undefined)}
      />

      <RenameServerModal
        show={Boolean(renameMachineData)}
        machineId={renameMachineData?.machineId}
        currentServerName={renameMachineData?.currentMachineName}
        onSubmit={onMachineRenameSubmitView}
        onClose={() => setRenameMachineData(undefined)}
        onHide={() => setRenameMachineData(undefined)}
      />

      <ConfirmationModal
        show={false}
        titleTextId={General.PaymentCompletedModalTitle}
        descriptionTextId={General.PaymentCompletedModalText}
        submitTextId={General.PaymentCompletedModalButtonText}
        onSubmit={() => console.log()}
        onCancel={() => console.log()}
      />
    </Container>
  );
};

export default DashboardHomeView;
