import { AxiosError } from "axios";
import { ClientType, ProjectType, RequestError } from "models/common";
import { AuthorizeUserModel, HistoryEntry, UserInfo } from "models/userModels";
import {
  authenticatedCoreAxios,
  coreAxios,
  handleAxiosError
} from "./apiService";

const user = "user";
const userEndpoint = (subRoute: string): string => user + subRoute;

export var authorizeByMicrosoft = (msToken: string) =>
  new Promise<string>((resolve, reject) => {
    coreAxios
      .post<string>(userEndpoint("/auth/ms"), null, {
        headers: { Authorization: msToken },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var authorize = (model: AuthorizeUserModel) =>
  new Promise<string>((resolve, reject) => {
    coreAxios
      .post<string>(userEndpoint("/auth/external"), {
        Code: model.code,
        Provider: model.provider,
        Project: ProjectType.FTP,
        Client: ClientType.Web,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var getUserInfo = (token: string) =>
  new Promise<UserInfo>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .get<UserInfo>(userEndpoint("/getInfo"))
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var refreshUserToken = (token: string) =>
  new Promise<string>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .get<string>(userEndpoint("/auth/refresh"))
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var getPurchaseHistory = (token: string, area: string = "FTP") =>
  new Promise<HistoryEntry[]>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .get<HistoryEntry[]>("/history", {
        params: {
          area: area,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });
