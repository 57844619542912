import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Error as ErrorResources, General } from "i18n/localizationText";
import { RequestError } from "models/common";

export var coreApiBaseUri = process.env.REACT_APP_CORE_API_BASE_URI || "";
export var ftpApiBaseUri = process.env.REACT_APP_FTP_API_BASE_URI || "";
export var coreApiBaseUriNoVersioning = coreApiBaseUri.slice(0, coreApiBaseUri.lastIndexOf('/', -1));;
export var stripeApiBaseUri = "https://api.stripe.com";

var coreApiConfig: AxiosRequestConfig = {
  baseURL: coreApiBaseUri,
};

var ftpApiConfig: AxiosRequestConfig = {
  baseURL: ftpApiBaseUri,
};

var stripeApiConfig: AxiosRequestConfig = {
  baseURL: stripeApiBaseUri,
};

var getAuthorizationHeader = (token: string) => ({
  headers: { Authorization: `Bearer ${token}` },
});

export var commonAxios = axios.create();
export var stripeAxios = (key: string) =>
  axios.create({ ...stripeApiConfig, ...getAuthorizationHeader(key) });

export var coreAxios = axios.create(coreApiConfig);
export var ftpAxios = axios.create(ftpApiConfig);

export var authenticatedCoreAxios = (token: string) =>
  axios.create({ ...coreApiConfig, ...getAuthorizationHeader(token) });
export var authenticatedFtpAxios = (token: string) =>
  axios.create({ ...ftpApiConfig, ...getAuthorizationHeader(token) });

export var handleAxiosError = (error: Error | AxiosError): RequestError => {
  if (axios.isAxiosError(error)) {
    return transformAxiosError(error);
  } else {
    return {
      Title: error.name,
      Errors: {
        error: [error.message],
      },
    };
    // Just a stock error
  }
};

export var transformAxiosError = (error: AxiosError): RequestError => {
  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    return error.response.data as RequestError;
  } else if (error.request) {
    // The request was made but no response was received
    return {
      Title: General.NoResponse,
      Errors: {},
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    return {
      Title: ErrorResources.Internal,
      Errors: {
        axios: [error.message],
      },
    };
  }
};

export default coreAxios;
