import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestState } from "models/common";
import {
  CheckoutInfomation, GetPurchasesResponse, Purchase
} from "models/purchaseModels";
import { getPurchases, getPurchasesAuthorized } from "services/purchaseService";
import { getToken, RootState } from "./store";

export interface PurchasingState {
  purchases?: Purchase[];
  stripeKey?: string;
  purchasesState: RequestState;
}

const initialState: PurchasingState = {
  purchasesState: RequestState.Idle
};

export const getPurchasesAsync = createAsyncThunk<
  GetPurchasesResponse,
  void,
  { state: RootState }
>(
  "history/getPurchases",
  async (_, api) => {
    let token = getToken(api.getState()) as string;
    if (token) {
      return await getPurchases("FTP");
    } else {
      return await getPurchasesAuthorized(token, "FTP");
    }
  }
);

export const purchasingSlice = createSlice({
  name: "purchasing",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPurchasesAsync.pending, (state) => {
        state.purchasesState = RequestState.Loading;
      })
      .addCase(getPurchasesAsync.fulfilled, (state, action) => {
        state.purchasesState = RequestState.Success;
        state.purchases = action.payload.purchases;
        state.stripeKey = action.payload.stripeKey;
      })
      .addCase(getPurchasesAsync.rejected, (state) => {
        state.purchasesState = RequestState.Failure;
      });
  },
});

export const {  } = purchasingSlice.actions;
export default purchasingSlice.reducer;
