import classNames from "classnames";
import IconButton from "components/IconButton";
import IconCross from "components/Icons/IconCross";
import StyledButton from "components/StyledButton";
import { General } from "i18n/localizationText";
import { PaymentInformation, Purchase } from "models/purchaseModels";
import { useEffect, useState } from "react";
import { Col, Form, Modal, ModalProps, Row, Stack } from "react-bootstrap";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "redux/store";
import { getStripePrice } from "services/paymentService";
import { getRenewPurchases } from "services/purchaseService";
import { parseDuration } from "utils/dateTimeHelpers";
import { getPurchase } from "utils/purchaseHelpers";
import "./RenewServerModal.css";

type RenewServerModalProps = {
  machineId?: string;

  onSubmit: (machineId: string, paymentInfo: PaymentInformation) => void;
  onClose?: () => void;
} & ModalProps;

const RenewServerModal = (props: RenewServerModalProps) => {
  const { machineId, onSubmit, onClose, ...modalProps } = props;

  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [selectedPriceId, setSelectedPriceId] = useState<string>();
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const state = useAppSelector((x) => x);
  const { core: coreState, purchasing: purchasingState } = state;
  const { stripeKey } = purchasingState;

  let intl = useIntl();
  const messages = defineMessages({
    durationValueFormat: {
      id: General.DurationValueFormat,
    },
  });

  let plans = purchases?.flatMap((purchase) => purchase.plans) || [];

  useEffect(() => {
    if (coreState.token && machineId) {
      getRenewPurchases(coreState.token, machineId).then((result) =>
        setPurchases(result.purchases)
      );
    }
  }, [machineId]);

  useEffect(() => {
    let plan = plans.find(x => true);
    setSelectedPriceId(plan?.priceId);
  }, [purchases]);

  useEffect(() => {
    refreshPrice();
  }, [selectedPriceId]);

  let refreshPrice = () => {
    if (selectedPriceId) {
      let paymentInfo = getPurchase(purchases, selectedPriceId);

      if (stripeKey && paymentInfo?.additionalId) {
        getStripePrice(stripeKey, paymentInfo.additionalId)
          .then((price: number) => setTotalPrice(price))
          .catch((err) => console.log(err));
      }
    }
  };

  let onModalSubmit = () => {
    if (selectedPriceId && machineId) {
      let paymentInfo = getPurchase(purchases, selectedPriceId);
      if (paymentInfo) {
        onSubmit?.call(this, machineId, paymentInfo);
      }
    }
  }

  return (
    <Modal
      {...modalProps}
      centered
      className={classNames(modalProps.className, "dialog-container")}
    >
      <IconButton
        clear
        icon={<IconCross color="#212529" />}
        className="dialog-close-button"
        onClick={() => onClose?.call(this)}
      />

      <Modal.Body>
        <Stack gap={3}>
          <Stack>
            <div className="dialog-header">
              <FormattedMessage id={General.Subscription} />
            </div>
            <div className="dialog-subheader">Buy subscripton to continue</div>
          </Stack>
          <Form.Group as={Col} controlId="renew.duration">
            <Form.Label>
              <FormattedMessage id={General.SubscriptionDuration} />
            </Form.Label>
            <Form.Select onChange={(el) => setSelectedPriceId(el.target.value)}>
              {plans?.map((plan) => {
                let duration = parseDuration(plan.duration);
                return (
                  <option key={plan.priceId} value={plan.priceId}>
                    {intl.formatMessage(messages.durationValueFormat, {
                      days: duration?.days(),
                    })}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Check
            className="default-checkbox"
            type="checkbox"
            label="I accept terms and conditions"
          />
          <Row>
            <Col className="renew-cost-label">
              <FormattedMessage id={General.Cost} values={{ value: totalPrice, currency: "$" }} />
            </Col>
            <Col>
              <StyledButton
                className="w-100"
                onClick={onModalSubmit}
              >
                <FormattedMessage id={General.BuyServer} />
              </StyledButton>
            </Col>
          </Row>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default RenewServerModal;
