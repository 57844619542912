import { AuthorizationProvider } from "models/common";
import { ROOT } from "navigation/routes";
import { Navigate, useSearchParams } from "react-router-dom";
import { authorizeAsync } from "redux/coreSlice";
import { useAppDispatch } from "redux/store";

type AuthorizationRedirectProps = {
  provider: AuthorizationProvider;
};

const AuthorizationRedirect = (props: AuthorizationRedirectProps) => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const code = searchParams.get("code");
  if (code) {
    dispatch(
      authorizeAsync({
        code: code,
        provider: props.provider,
      })
    );
  }

  return <Navigate to={ROOT} replace={false} />;
};

export default AuthorizationRedirect;
