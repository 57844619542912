import FlatCard from "components/FlatCard";
import IconBag from "components/Icons/IconBag";
import IconInfo from "components/Icons/IconInfo";
import IconPrivacy from "components/Icons/IconPrivacy";
import IconShare from "components/Icons/IconShare";
import StyledButton, { StyledButtonType } from "components/StyledButton";
import { Dashboard, General } from "i18n/localizationText";
import {
  guideUrl,
  moreAppsUrl,
  privacyPolicyUrl,
  siteUrl
} from "models/constants";
import { Container, OverlayTrigger, Popover, Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import "./About.css";

type AboutViewProps = {};

const AboutView = (props: AboutViewProps) => {
  let navigate = useNavigate();

  const sharePopup = (
    <Popover>
      <Popover.Body>
        <Stack direction="horizontal" gap={2}>
          <EmailShareButton url={siteUrl}>
            <EmailIcon size={36} round={true} />
          </EmailShareButton>
          <FacebookShareButton url={siteUrl}>
            <FacebookIcon size={36} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={siteUrl}>
            <TwitterIcon size={36} round={true} />
          </TwitterShareButton>
          <WhatsappShareButton url={siteUrl}>
            <WhatsappIcon size={36} round={true} />
          </WhatsappShareButton>
          <LinkedinShareButton url={siteUrl}>
            <LinkedinIcon size={36} round={true} />
          </LinkedinShareButton>
        </Stack>
      </Popover.Body>
    </Popover>
  );

  return (
    <Container fluid className="about-container">
      <FlatCard className="about-card">
        <Stack gap={3}>
          <div className="about-title">
            <FormattedMessage id={Dashboard.AboutTitle} />
          </div>
          <div className="about-text">
            <FormattedMessage id={Dashboard.AboutText} />
          </div>

          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            overlay={sharePopup}
          >
            <div className="share-button-container">
              <StyledButton className="w-100">
                <IconShare />
                <FormattedMessage id={General.Share} />
              </StyledButton>
            </div>
          </OverlayTrigger>

          <StyledButton
            buttonType={StyledButtonType.Clear}
            onClick={() => window.open(moreAppsUrl, "_blank")?.focus()}
          >
            <IconBag />
            <FormattedMessage id={Dashboard.MoreApps} />
          </StyledButton>

          <StyledButton
            buttonType={StyledButtonType.Clear}
            onClick={() => window.open(privacyPolicyUrl, "_blank")?.focus()}
          >
            <IconPrivacy />
            <FormattedMessage id={General.PrivacyPolicy} />
          </StyledButton>

          <StyledButton
            buttonType={StyledButtonType.Clear}
            onClick={() => window.open(guideUrl, "_blank")?.focus()}
          >
            <IconInfo />
            <FormattedMessage id={General.Guide} />
          </StyledButton>
        </Stack>
      </FlatCard>
    </Container>
  );
};

export default AboutView;
