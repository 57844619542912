import { useAppDispatch, useAppSelector } from "redux/store";
import { getHistoryAsync } from "redux/historySlice";
import HistoryView from "./HistoryView";
import { useEffect } from "react";
import { RequestState } from "models/common";

export function HistoryContainer() {
  let state = useAppSelector((x) => x);
  let dispatch = useAppDispatch();
  const { history: historyState, dashboard: dashboardState } = state;

  useEffect(() => {
    if (!historyState.entries && historyState.entriesState != RequestState.Loading) {
      onRefresh();
    }
  }, []);

  let onRefresh = () => {
    dispatch(getHistoryAsync());
  }

  let entries = historyState.entries || [];
  if (dashboardState.searchText){
    entries = entries.filter(x => x.machineName.toLowerCase().includes(dashboardState.searchText.toLowerCase()));
  }
  
  return (
    <>
      <HistoryView 
        entries={entries}
        entriesState={historyState.entriesState}
        searchHighlightedText={dashboardState.searchText}
        onRefresh={onRefresh}
      />
    </>
  );
}
