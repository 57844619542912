import classNames from "classnames";
import IconButton from "components/IconButton";
import IconArrowLeft from "components/Icons/IconArrowLeft";
import IconCross from "components/Icons/IconCross";
import { General } from "i18n/localizationText";
import { BuyServerStage, BuyServerViewModel, FtpCheckoutViewModel } from "models/viewModels";
import { Modal, ModalProps, Stack } from "react-bootstrap";
import {
  FormProvider, UseFormReturn
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import "./BuyServerModal.css";
import CoreInformation from "./components/CoreInformation";
import FtpInformation from "./components/FtpInformation";

type BuyServerModalViewProps = {
  availableSizes?: string[];
  availableDurations?: string[];
  total: number;
  stage: BuyServerStage;

  coreForm: UseFormReturn<BuyServerViewModel>,
  ftpForm: UseFormReturn<FtpCheckoutViewModel>,

  onPaymentInfoSubmit: (info: BuyServerViewModel) => void;
  onFtpInfoSubmit: (info: FtpCheckoutViewModel) => void;
  onClose?: () => void;
  onBack?: () => void;
} & ModalProps;

const BuyServerModalView = (props: BuyServerModalViewProps) => {
  const {
    availableSizes,
    availableDurations,
    total,
    stage,
    coreForm,
    ftpForm,
    onPaymentInfoSubmit,
    onFtpInfoSubmit,
    onClose,
    onBack,
    ...modalProps
  } = props;

  let renderContent = (stage: BuyServerStage) => {
    switch (stage) {
      case BuyServerStage.CoreInformation:
        return (
          <FormProvider {...coreForm}>
            <CoreInformation
              availableSizes={availableSizes}
              availableDurations={availableDurations}
              total={total}
              onSubmit={onPaymentInfoSubmit}
            />
          </FormProvider>
        );
      case BuyServerStage.FtpServerInfo:
        return (
          <FormProvider {...ftpForm}>
            <FtpInformation
              total={total}
              onSubmit={onFtpInfoSubmit}
            />
          </FormProvider>
        );
    }
  };

  return (
    <Modal
      {...modalProps}
      centered
      className={classNames(modalProps.className, "dialog-container")}
    >
      {stage !== BuyServerStage.CoreInformation ? (
        <IconButton
          clear
          icon={<IconArrowLeft color="#212529" />}
          className="dialog-back-button"
          onClick={() => onBack?.call(this)}
        />
      ) : (
        <></>
      )}

      <IconButton
        clear
        icon={<IconCross color="#212529" />}
        className="dialog-close-button"
        onClick={() => onClose?.call(this)}
      />

      <Modal.Body>
        <Stack gap={2}>
          <Stack>
            <div className="dialog-header">
              <FormattedMessage id={General.Subscription} />
            </div>
            <div className="dialog-subheader">Buy subscripton to continue</div>
          </Stack>
          {renderContent(stage)}
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default BuyServerModalView;
