import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RequestState } from "models/common";
import { HistoryEntry } from "models/userModels";
import { getPurchaseHistory } from "services/userService";
import { getToken, RootState } from "./store";

export interface HistoryState {
  entries?: HistoryEntry[];
  entriesState: RequestState;
}

const initialState: HistoryState = {
  entriesState: RequestState.Idle,
};

export const getHistoryAsync = createAsyncThunk<
  HistoryEntry[],
  void,
  { state: RootState }
>(
  "history/getHistory",
  async (_, api) => {
    let token = getToken(api.getState()) as string;
    return await getPurchaseHistory(token, "FTP");
  },
  {
    condition: (_, api) => Boolean(api.getState()?.core?.token),
  }
);

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHistoryAsync.pending, (state) => {
        state.entriesState = RequestState.Loading;
      })
      .addCase(getHistoryAsync.fulfilled, (state, action) => {
        state.entriesState = RequestState.Success;
        state.entries = action.payload;
      })
      .addCase(getHistoryAsync.rejected, (state) => {
        state.entriesState = RequestState.Failure;
      });
  },
});

//export const {  } = coreSlice.actions;

export default historySlice.reducer;
