export const moreAppsUrl = "https://apps.microsoft.com/store/search?publisher=Yellow%20Elephant%20Productions";
export const desktopAppUrl = "https://www.microsoft.com/store/apps/9N6817VNCZG8";
export const privacyPolicyUrl = "http://tidalmediainc.com/privacy-policy/";
export const termsOfUseUrl = "http://tidalmediainc.com/license-terms/";
//TODO: Replace with real one
export const guideUrl = "https://google.com/";
export const siteUrl = "https://privateftp.biz/";

export enum LocalStorageKey {
  AuthSuccessRedirect = "auth-success-redirect"
}