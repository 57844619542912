import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Store from "redux/store";
import "styles/globalStyles.css";
import App from "./App";
import * as serviceWorker from "./serviceWorkerRegistration";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store.default}>
      <PersistGate loading={null} persistor={Store.persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();

//reportWebVitals();

ReactGA.initialize(process.env.REACT_APP_GA || "");
ReactGA.pageview(window.location.pathname + window.location.search);
