import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import IconButton from "components/IconButton";
import IconCross from "components/Icons/IconCross";
import StyledButton from "components/StyledButton";
import { Checkout, General } from "i18n/localizationText";
import { CreateUserViewModel, createUserViewModelSchema } from "models/viewModels";
import { Form, Modal, ModalProps, Row, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { localizeYupError } from "utils/yup";
import "./CreateUserModal.css";

type CreateUserModalProps = {
  machineId?: string;

  onSubmit: (machineId: string, username: string, password: string) => void;
  onClose?: () => void;
} & ModalProps;

const CreateUserModal = (props: CreateUserModalProps) => {
  const { machineId, onSubmit, onClose, ...modalProps } = props;
  const intl = useIntl();

  const { register, handleSubmit, formState, reset } = useForm<CreateUserViewModel>({
    mode: "onBlur",
    resolver: yupResolver(createUserViewModelSchema),
  });

  let onModalSubmit = (viewModel: CreateUserViewModel) => {
    if (machineId) {
      reset({
        username: '',
        password: '',
        passwordConfirmation: ''
      });
      onSubmit?.call(this, machineId, viewModel.username, viewModel.password);
    }
  }

  return (
    <Modal
      {...modalProps}
      centered
      className={classNames(modalProps.className, "dialog-container")}
    >
      <IconButton
        clear
        icon={<IconCross color="#212529" />}
        className="dialog-close-button"
        onClick={() => onClose?.call(this)}
      />

      <Modal.Body>
        <Stack gap={3}>
          <Stack>
            <div className="dialog-header">
              <FormattedMessage id={Checkout.AddNewUser} />
            </div>
          </Stack>

          <Form.Group controlId="changeUserPassword.username">
            <Form.Label>
              <FormattedMessage id={General.Username} />
            </Form.Label>
            <Form.Control
              className="default-input"
              type="text"
              isInvalid={!!formState.errors.username}
              {...register("username")}
            />
          </Form.Group>

          <Form.Group controlId="changeUserPassword.password">
            <Form.Label>
              <FormattedMessage id={General.Password} />
            </Form.Label>
            <Form.Control
              className="default-input"
              type="password"
              isInvalid={!!formState.errors.password}
              {...register("password")}
            />
            <Form.Control.Feedback type="invalid">
              {localizeYupError(intl, formState.errors.password)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="changeUserPassword.passwordConfirmation">
            <Form.Label>
              <FormattedMessage id={General.ConfirmPassword} />
            </Form.Label>
            <Form.Control
              className="default-input"
              type="password"
              isInvalid={!!formState.errors.passwordConfirmation}
              {...register("passwordConfirmation")}
            />
            <Form.Control.Feedback type="invalid">
              {localizeYupError(intl, formState.errors.passwordConfirmation)}
            </Form.Control.Feedback>
          </Form.Group>

          <Row className="justify-content-center">
            <StyledButton
              className="w-100"
              onClick={handleSubmit(onModalSubmit)}
            >
              <FormattedMessage id={General.Save} />
            </StyledButton>
          </Row>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserModal;
