import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import IconButton from "components/IconButton";
import IconCross from "components/Icons/IconCross";
import StyledButton from "components/StyledButton";
import { Dashboard, General } from "i18n/localizationText";
import { RenameServerViewModel, renameServerViewModelSchema } from "models/viewModels";
import { Col, Form, Modal, ModalProps, Row, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { localizeYupError } from "utils/yup";
import "./RenameServerModal.css";

type RenameServerModalProps = {
  machineId?: string;
  currentServerName?: string;

  onSubmit: (machineId: string, newName: string) => void;
  onClose?: () => void;
} & ModalProps;

const RenameServerModal = (props: RenameServerModalProps) => {
  const { machineId, currentServerName, onSubmit, onClose, ...modalProps } = props;
  const intl = useIntl();

  const { register, handleSubmit, formState } = useForm<RenameServerViewModel>({
    mode: "all",
    defaultValues: {
      newName: currentServerName
    },
    resolver: yupResolver(renameServerViewModelSchema),
  });

  let onModalSubmit = (viewModel: RenameServerViewModel) => {
    if (machineId && onSubmit) {
      onSubmit.call(this, machineId, viewModel.newName);
    }
  }

  return (
    <Modal
      {...modalProps}
      centered
      className={classNames(modalProps.className, "dialog-container")}
    >
      <IconButton
        clear
        icon={<IconCross color="#212529" />}
        className="dialog-close-button"
        onClick={() => onClose?.call(this)}
      />

      <Modal.Body>
        <Stack gap={3}>
          <Stack>
            <div className="dialog-header">
              <FormattedMessage id={Dashboard.EditServerName} />
            </div>
          </Stack>

          <Form.Group as={Col} controlId="rename.newName">
            <Form.Label>
              <FormattedMessage id={General.ServerName} />
            </Form.Label>
            <Form.Control
              className="default-input"
              type="text"
              isInvalid={!!formState.errors.newName}
              {...register("newName")}
            />
            <Form.Control.Feedback type="invalid">
              {localizeYupError(intl, formState.errors.newName)}
            </Form.Control.Feedback>
          </Form.Group>

          <Row className="justify-content-center">
            <StyledButton
              className="w-100"
              onClick={handleSubmit(onModalSubmit)}
            >
              <FormattedMessage id={General.Save} />
            </StyledButton>
          </Row>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default RenameServerModal;
