export enum RequestState {
    Idle = "idle",
    Loading = "loading",
    Failure = "failure",
    Success = "success"
}

export enum PaymentPurpose {
    Create = 1,
    Renew
}

export interface RequestError {
    Type?: string,
    Title: string,
    Status?: number,
    TraceId?: string,
    Errors: Record<string, string[]>
}

export interface ErrorEntry {
    member: string,
    errors: ErrorMessageEntry[]
}

export interface ErrorMessageEntry {
    code: string,
    message: string
}

export enum AuthorizationProvider {
    Microsoft,
    Google,
    Facebook
}

export enum ProjectType {
    FTP,
    Minecraft
}

export enum ClientType {
    Windows,
    Web
}