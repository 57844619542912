import { yupResolver } from "@hookform/resolvers/yup";
import { RequestState } from "models/common";
import { PaymentInformation, Purchase } from "models/purchaseModels";
import {
  BuyServerViewModel,
  buyServerViewModelSchema
} from "models/viewModels";
import { MachineShortInfo } from "models/virtualMachineModels";
import { FormProvider, useForm } from "react-hook-form";
import { getAppropriatePurchase } from "utils/purchaseHelpers";
import BuyServerView from "./BuyServerView";

type BuyServerContainerProps = {
  purchases?: Purchase[];
  purchasesState: RequestState;
  onSubmit?: (info: MachineShortInfo, paymentInfo: PaymentInformation) => void;
};

export const BuyServerContainer = (props: BuyServerContainerProps) => {
  let { purchases, purchasesState, onSubmit } = props;

  let availableSizes =
    purchases?.map((purchase) => purchase.additionalData.Size) || [];
  let plans = purchases?.flatMap((purchase) => purchase.plans) || [];
  let availableDurations = plans.map((plan) => plan.duration);

  let defaultSize = availableSizes.find(() => true);
  const form = useForm<BuyServerViewModel>({
    mode: "all",
    defaultValues: {
      name: "",
      diskSize: defaultSize,
      duration: availableDurations.find(() => true),
      isStaticIp: true
    },
    resolver: yupResolver(buyServerViewModelSchema),
  });

  let onSummarySubmit = (info: MachineShortInfo) => {
    let paymentInfo = getAppropriatePurchase(purchases || [], info);
    if (paymentInfo) {
      onSubmit?.call(this, info, paymentInfo);
    }
  };

  return (
    <FormProvider {...form}>
      <BuyServerView
        availableSizes={availableSizes}
        availableDurations={availableDurations}
        purchasesState={purchasesState}
        onSummarySubmit={onSummarySubmit}
      />
    </FormProvider>
  );
};
