import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestState } from "models/common";
import { ChangeSubscriptionStatusModel } from "models/dashboardModels";
import { VirtualMachineShortModel } from "models/virtualMachineModels";
import { cancelSubscriptionStripe, changeSubscriptionPauseStatusStripe } from "services/purchaseService";
import { getAllMachines } from "services/virtualMachineService";
import { getMachineInfoAsync } from "./ftpServerSlice";
import { getToken, RootState } from "./store";

export interface DashboardState {
  virtualMachines?: VirtualMachineShortModel[];
  virtualMachinesState: RequestState;

  selectedVirtualMachine?: VirtualMachineShortModel;
  subscriptionOperationState: RequestState;

  searchText: string;
}

const initialState: DashboardState = {
  virtualMachinesState: RequestState.Idle,
  subscriptionOperationState: RequestState.Idle,
  searchText: ""
};

export const getVirtualMachinesAsync = createAsyncThunk<
  VirtualMachineShortModel[],
  void,
  { state: RootState }
>(
  "core/getVirtualMachines",
  async (_, api) => {
    let token = getToken(api.getState()) as string;
    return await getAllMachines(token, "FTP");
  },
  {
    condition: (_, api) => Boolean(api.getState()?.core?.token),
  }
);

export const changeSubscriptionPauseStatusAsync = createAsyncThunk<
  boolean,
  ChangeSubscriptionStatusModel,
  { state: RootState }
>(
  "core/changeSubscriptionPauseStatus",
  async (model, api) => {
    let token = getToken(api.getState()) as string;
    await changeSubscriptionPauseStatusStripe(token, model.machineId, model.status);
    return model.status;
  },
  {
    condition: (_, api) => Boolean(api.getState()?.core?.token),
  }
);

export const cancelSubscriptionAsync = createAsyncThunk<
  void,
  string,
  { state: RootState }
>(
  "core/cancelSubscription",
  async (machineId, api) => {
    let token = getToken(api.getState()) as string;
    await cancelSubscriptionStripe(token, machineId);
  },
  {
    condition: (_, api) => Boolean(api.getState()?.core?.token),
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    selectVirtualMachine: (
      state,
      action: PayloadAction<VirtualMachineShortModel>
    ) => {
      state.selectedVirtualMachine = action.payload;
    },
    setSearchText: (
      state,
      action: PayloadAction<string>
    ) => {
      state.searchText = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMachineInfoAsync.fulfilled, (state, action) => {
        let { payload: machine } = action;
        if (state.selectedVirtualMachine && state.selectedVirtualMachine.id === machine.id) {
          state.selectedVirtualMachine.status = machine.status;
        }
      })
      .addCase(changeSubscriptionPauseStatusAsync.fulfilled, (state, action) => {
        let { payload: status } = action;
        if (state.selectedVirtualMachine && state.selectedVirtualMachine.subscriptionInfo) {
          state.selectedVirtualMachine.subscriptionInfo.isPaused = status;
        }
      })
      .addCase(cancelSubscriptionAsync.fulfilled, (state, _) => {
        if (state.selectedVirtualMachine) {
          state.selectedVirtualMachine.subscriptionInfo = undefined;
        }
      })
      .addCase(getVirtualMachinesAsync.pending, (state) => {
        state.virtualMachinesState = RequestState.Loading;
      })
      .addCase(getVirtualMachinesAsync.fulfilled, (state, action) => {
        state.virtualMachinesState = RequestState.Success;
        state.virtualMachines = action.payload;
      })
      .addCase(getVirtualMachinesAsync.rejected, (state) => {
        state.virtualMachinesState = RequestState.Failure;
      })
      .addMatcher(
        (action) => action.type.startsWith("core/") && action.type.includes("Subscription") && action.type.endsWith("/pending"),
        (state) => {
          state.subscriptionOperationState = RequestState.Loading;
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("core/") && action.type.includes("Subscription") && action.type.endsWith("/fulfilled"),
        (state) => {
          state.subscriptionOperationState = RequestState.Success;
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("core/") && action.type.includes("Subscription") && action.type.endsWith("/rejected"),
        (state) => {
          state.subscriptionOperationState = RequestState.Failure;
        }
      );
  },
});

export const { selectVirtualMachine, setSearchText } = dashboardSlice.actions;
export default dashboardSlice.reducer;
