import FlatCard from "components/FlatCard";
import { General } from "i18n/localizationText";
import { RequestState } from "models/common";
import { BuyServerViewModel } from "models/viewModels";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import {
  useFormContext
} from "react-hook-form";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { parseDuration } from "utils/dateTimeHelpers";
import { localizeYupError } from 'utils/yup';
import "./BuyServer.css";

type ConfigurationProps = {
  availableSizes?: string[];
  availableDurations?: string[];

  purchasesState: RequestState;
};

const Configuration = (props: ConfigurationProps) => {
  let {
    availableSizes,
    availableDurations,
    purchasesState,
  } = props;

  const { register, formState } = useFormContext<BuyServerViewModel>();

  const intl = useIntl();
  const messages = defineMessages({
    staticIpLabel: {
      id: General.IpAddressStatic,
    },
    dynamicIpLabel: {
      id: General.IpAddressDynamic,
    },
    spaceValueFormat: {
      id: General.DiskSpaceValueFormat,
    },
    durationValueFormat: {
      id: General.DurationValueFormat,
    },
  });

  if (purchasesState === RequestState.Loading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <FlatCard className="landing-configuration-container">
        <Form noValidate>
          <Row className="mb-3">
            <Col xs={12}>
              <h3 className="landing-configuration-title">
                <FormattedMessage id={General.Configuration} />
              </h3>
            </Col>

            <Form.Group as={Col} controlId="configuration.serverName">
              <Form.Label>
                <FormattedMessage id={General.ServerName} />
              </Form.Label>
              <Form.Control
                className="default-input"
                type="text"
                isInvalid={!!formState.errors.name}
                {...register("name")}
              />
              <Form.Control.Feedback type="invalid">
                {localizeYupError(intl, formState.errors.name)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="configuration.diskSize">
              <Form.Label>
                <FormattedMessage id={General.DiskSize} />
              </Form.Label>

              <Form.Select
                className="default-select"
                isInvalid={!!formState.errors.diskSize}
                {...register("diskSize")}
              >
                {availableSizes?.map((size) => (
                  <option key={size} value={size}>
                    {intl.formatMessage(messages.spaceValueFormat, {
                      size: size,
                    })}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {localizeYupError(intl, formState.errors.diskSize)}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="configuration.duration">
              <Form.Label>
                <FormattedMessage id={General.SubscriptionDuration} />
              </Form.Label>
              <Form.Select
                className="default-select"
                isInvalid={!!formState.errors.duration}
                {...register("duration")}
              >
                {availableDurations?.map((strDuration) => {
                  let duration = parseDuration(strDuration);
                  return (
                    <option key={strDuration} value={strDuration}>
                      {intl.formatMessage(messages.durationValueFormat, {
                        days: duration?.days(),
                      })}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {localizeYupError(intl, formState.errors.duration)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="configuration.ipAddress">
              <Form.Label>
                <FormattedMessage id={General.IpAddressLabel} />
              </Form.Label>
              <Form.Select
                className="default-select"
                isInvalid={!!formState.errors.isStaticIp}
                {...register("isStaticIp")}
              >
                <option key="true" value="true">
                  {intl.formatMessage(messages.staticIpLabel)}
                </option>
                <option key="false" value="false">
                  {intl.formatMessage(messages.dynamicIpLabel)}
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {localizeYupError(intl, formState.errors.isStaticIp)}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      </FlatCard>
    );
  }
};

export default Configuration;
