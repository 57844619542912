import Footer from 'components/Footer';
import './Layout.css';

type LayoutProps = {
    children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
    return (
        <>
            {props.children}
            <Footer />
        </>
    )
}

export default Layout;