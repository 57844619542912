import { Dashboard } from "i18n/localizationText";
import { HistoryEntry as HistoryEntryModel } from "models/userModels";
import { Col, Row, Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { dayjs } from "utils/dateTimeHelpers";
import { formatFtpIpAddress } from "utils/ftpHelpers";
import { HighlightedText } from 'utils/reactHelpers';
import "./HistoryEntry.css";

type HistoryEntryProps = {
  entry: HistoryEntryModel;
  highlightText?: string;
};

const HistoryEntry = (props: HistoryEntryProps) => {
  let { entry, highlightText } = props;

  return (
    <div className="history-entry-container">
      <Row>
        <Col xs={8}>
          <Stack>
            <div className="history-entry-title">
              {
                Boolean(highlightText)
                  ? (
                    <HighlightedText text={entry.machineName} highlight={highlightText} />
                  )
                  : (
                    <span>{entry.machineName}</span>
                  )
              }
            </div>
            <div className="history-entry-text">
              {Boolean(entry.ipAddress) ? (
                formatFtpIpAddress(entry.ipAddress)
              ) : (
                <></>
              )}
            </div>
          </Stack>
        </Col>
        <Col xs={4}>
          <Stack>
            <div className="history-entry-text">
              <FormattedMessage id={Dashboard.ValidityPeriod} />
            </div>
            <div className="history-entry-date">
              {dayjs(entry.from).format("L")} - {dayjs(entry.to).format("L")}
            </div>
          </Stack>
        </Col>
      </Row>
    </div>
  );
};

export default HistoryEntry;
