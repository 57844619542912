import StyledButton from "components/StyledButton";
import { General } from "i18n/localizationText";
import { BuyServerViewModel } from "models/viewModels";
import { Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { parseDuration } from "utils/dateTimeHelpers";
import { localizeYupError } from "utils/yup";

type CoreInformationProps = {
    availableSizes?: string[];
    availableDurations?: string[];
    total: number;

    onSubmit: (info: BuyServerViewModel) => void;
};

const CoreInformation = (props: CoreInformationProps) => {
    const { availableSizes, availableDurations, total, onSubmit } = props;

    const { formState, register, handleSubmit } = useFormContext<BuyServerViewModel>();
    const intl = useIntl();
    const messages = defineMessages({
        staticIpLabel: {
            id: General.IpAddressStatic,
        },
        dynamicIpLabel: {
            id: General.IpAddressDynamic,
        },
        spaceValueFormat: {
            id: General.DiskSpaceValueFormat,
        },
        durationValueFormat: {
            id: General.DurationValueFormat,
        },
    });

    return (
        <>
            <Form.Group controlId="buy.serverName">
                <Form.Label>
                    <FormattedMessage id={General.ServerName} />
                </Form.Label>
                <Form.Control
                    className="default-input"
                    type="text"
                    isInvalid={!!formState.errors.name}
                    {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                    {localizeYupError(intl, formState.errors.name)}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="buy.diskSize">
                <Form.Label>
                    <FormattedMessage id={General.DiskSize} />
                </Form.Label>

                <Form.Select
                    className="default-select"
                    isInvalid={!!formState.errors.diskSize}
                    {...register("diskSize")}
                >
                    {availableSizes?.map((size) => (
                        <option key={size} value={size}>
                            {intl.formatMessage(messages.spaceValueFormat, {
                                size: size,
                            })}
                        </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {localizeYupError(intl, formState.errors.diskSize)}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="buy.duration">
                <Form.Label>
                    <FormattedMessage id={General.SubscriptionDuration} />
                </Form.Label>
                <Form.Select
                    className="default-select"
                    isInvalid={!!formState.errors.duration}
                    {...register("duration")}
                >
                    {availableDurations?.map((strDuration) => {
                        let duration = parseDuration(strDuration);
                        return (
                            <option key={strDuration} value={strDuration}>
                                {intl.formatMessage(messages.durationValueFormat, {
                                    days: duration?.days(),
                                })}
                            </option>
                        );
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {localizeYupError(intl, formState.errors.duration)}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="buy.ipAddress">
                <Form.Label>
                    <FormattedMessage id={General.IpAddressLabel} />
                </Form.Label>
                <Form.Select
                    className="default-select"
                    isInvalid={!!formState.errors.isStaticIp}
                    {...register("isStaticIp")}
                >
                    <option key="true" value="true">
                        {intl.formatMessage(messages.staticIpLabel)}
                    </option>
                    <option key="false" value="false">
                        {intl.formatMessage(messages.dynamicIpLabel)}
                    </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {localizeYupError(intl, formState.errors.isStaticIp)}
                </Form.Control.Feedback>
            </Form.Group>

            <Row className="mt-2">
                <Col className="buy-server-cost-label">
                    <FormattedMessage
                        id={General.Cost}
                        values={{ value: total, currency: "$" }}
                    />
                </Col>
                <Col>
                    <StyledButton
                        className="w-100"
                        onClick={handleSubmit(onSubmit)}
                    >
                        <FormattedMessage id={General.Proceed} />
                    </StyledButton>
                </Col>
            </Row>
        </>
    );
}

export default CoreInformation;