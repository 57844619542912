import cube from "assets/images/landing_cube.png";
import screen from "assets/images/landing_screenshot.png";
import Header from "components/Header";
import StyledButton from "components/StyledButton";
import { General, Landing } from "i18n/localizationText";
import { RequestState } from "models/common";
import { PaymentInformation, Purchase } from "models/purchaseModels";
import { MachineShortInfo } from "models/virtualMachineModels";
import { useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import BuyServer from "./components/BuyServer";
import "./Landing.css";

type LandingProps = {
  purchases?: Purchase[];
  purchasesState: RequestState;

  onBuyServerSubmit?: (
    info: MachineShortInfo,
    paymentInfo: PaymentInformation
  ) => void;
};

const LandingView = (props: LandingProps) => {
  let { purchases, purchasesState, onBuyServerSubmit } = props;

  let buyServerRef = useRef<null | HTMLHeadingElement>(null);
  let scrollToBuyServer = () => {
    if (buyServerRef && buyServerRef.current) {
      buyServerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Container>
      <Header showDashboardButton />

      <Row className="justify-content-center">
        <Col xs={12}>
          <h1 className="landing-main-title">
            <FormattedMessage id={Landing.Title} />
          </h1>
        </Col>
        <Col xs={8}>
          <div className="landing-main-text">
            <FormattedMessage id={Landing.MainText} />
          </div>
        </Col>
      </Row>
      <Row className="landing-buy-server-button justify-content-center">
        <StyledButton onClick={scrollToBuyServer}>
          <FormattedMessage id={General.BuyServer} />
        </StyledButton>
      </Row>
      <Row>
        <Image className="landing-main-image" src={screen} />
      </Row>
      <Row className="landing-about-block">
        <Col xs={7} className="d-flex align-items-center p-0">
          <div>
            <h2 className="landing-secondary-title">
              <FormattedMessage id={Landing.AboutTitle} />
            </h2>
            <span>
              <FormattedMessage id={Landing.AboutText} />
            </span>
          </div>
        </Col>
        <Col xs={5} className="text-end p-0">
          <Image src={cube} />
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="p-0 text-center">
          <h1
            ref={buyServerRef}
            id="buyServer"
            className="landing-secondary-title mb-4"
          >
            <FormattedMessage id={General.BuyServer} />
          </h1>
        </Col>
      </Row>
      <Row className="landing-buy-server-outer">
        <BuyServer
          purchases={purchases}
          purchasesState={purchasesState}
          onSubmit={onBuyServerSubmit}
        />
      </Row>
    </Container>
  );
};

export default LandingView;
