import "./IconButton.css";
import { Button, ButtonProps } from "react-bootstrap";
import classNames from "classnames";

type IconButtonProps = {
  icon: JSX.Element;
  clear?: boolean;
} & ButtonProps;

const IconButton = (props: IconButtonProps) => {
  let { icon, className, clear, ...buttonProps } = props;
  let combinedClasses = classNames(className, "icon-button");

  if (clear) {
    combinedClasses = classNames(combinedClasses, "icon-button-clear");
  }

  return (
    <Button {...buttonProps} className={combinedClasses}>
      {icon}
    </Button>
  );
};

export default IconButton;
