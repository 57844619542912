import "./Divider.css";
import classNames from 'classnames';

type DividerProps = {
    className?: string
}

const Divider = (props: DividerProps) => (
    <div className={classNames("divider", props.className)}></div>
)

export default Divider;