import classNames from "classnames";
import ExpirationLabel from "components/ExpirationLabel";
import IconButton from "components/IconButton";
import IconClockHistory from "components/Icons/IconClockHistory";
import IconDelete from "components/Icons/IconDelete";
import IconEdit from "components/Icons/IconEdit";
import { Dashboard } from "i18n/localizationText";
import { VirtualMachineShortModel } from "models/virtualMachineModels";
import { Col, Row, Stack } from "react-bootstrap";
import { defineMessages, useIntl } from "react-intl";
import { HighlightedText } from 'utils/reactHelpers';
import "./MachineEntry.css";

type MachineEntryProps = {
  machine: VirtualMachineShortModel;
  isSelected?: boolean;
  highlightText?: string;

  onSelect?: (machine: VirtualMachineShortModel) => void;
  onRenewClick?: (machine: VirtualMachineShortModel) => void;
  onEditClick?: (machine: VirtualMachineShortModel) => void;
};

const MachineEntry = (props: MachineEntryProps) => {
  let { machine, isSelected, highlightText, onSelect, onRenewClick, onEditClick } = props;
  const intl = useIntl();
  const messages = defineMessages({
    renewTooltip: {
      id: Dashboard.RenewSubscription,
    },
    editTooltip: {
      id: Dashboard.EditServerName,
    }
  });
  let isSelectedSelector = {
    "machine-entry-container-selected": isSelected || false,
  };
  return (
    <div
      className={classNames("machine-entry-container", isSelectedSelector)}
      onClick={() => onSelect?.call(this, machine)}
    >
      <Row className="align-content-center">
        <Col className="d-flex align-items-center" xs={5}>
          <div className="machine-entry-name">
            {
              Boolean(highlightText)
                ? (
                  <HighlightedText text={machine.publicName} highlight={highlightText} />
                )
                : (
                  <span>{machine.publicName}</span>
                )
            }
          </div>
        </Col>
        <Col xs={{ span: 3, offset: 1 }}>
          <ExpirationLabel
            showText
            boughtDate={machine.lastStartDate}
            expirationDate={machine.dueDate}
          />
        </Col>
        <Col xs={{ span: 2, offset: 1 }} className="d-flex justify-content-end">
          <Stack direction="horizontal" gap={3}>
            <IconButton
              clear
              icon={<IconClockHistory color="#19B500" />}
              title={intl.formatMessage(messages.renewTooltip)}
              onClick={() => onRenewClick?.call(this, machine)}
            />
            <IconButton
              clear
              icon={<IconEdit color="#212529" />}
              title={intl.formatMessage(messages.editTooltip)}
              onClick={() => onEditClick?.call(this, machine)}
            />
          </Stack>
        </Col>
      </Row>
    </div>
  );
};

export default MachineEntry;
