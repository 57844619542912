import "./LanguagePicker.css";
import { Language, LanguagePublicName } from "i18n";
import IconLanguage from "components/Icons/IconLanguage";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { General } from 'i18n/localizationText';

type LanguagePickerProps = {
  currentLanguage: Language;
  showIconAndLabel?: boolean;
  onLanguageChange?: (language: Language) => void;
};

const LanguagePicker = (props: LanguagePickerProps) => {
  let {
    currentLanguage, showIconAndLabel,
    onLanguageChange 
  } = props;
  showIconAndLabel = showIconAndLabel || true;

  let renderIconAndLabel = () => {
    if (showIconAndLabel){
      return (
        <>
          <IconLanguage className="language-picker-icon" />
          <span className="language-picker-label" >
            <FormattedMessage id={General.Language} />
          </span>
        </>
      );
    }
    return (<></>)
  }

  let onSelect = (key: any) => {
    if (key && onLanguageChange){
      onLanguageChange(key as Language);
    }
  }

  return (
    <div className="language-picker-container">
      {
        renderIconAndLabel()
      }

      <Dropdown 
        as={ButtonGroup} 
        className="language-picker"
        onSelect={onSelect}
      >
        <Dropdown.Toggle className="language-picker-toggle">
          <span>{LanguagePublicName[currentLanguage]}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="language-picker-menu">
          {Object.entries(LanguagePublicName).map(([key, value]) => (
            <Dropdown.Item
              key={key}
              eventKey={key}
              className="language-picker-menu-item"
              active={currentLanguage === key}
            >
              {value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LanguagePicker;
