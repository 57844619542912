import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestState } from "models/common";
import {
  AddUserRequestModel,
  ChangeBlockStateModel,
  ChangeUserPasswordRequestModel,
  FtpUser,
  RemoveUserModel
} from "models/ftpModels";
import { FtpVirtualMachineFullInfo, VirtualMachineFullModel } from "models/virtualMachineModels";
import {
  addUser,
  blockUser,
  changeUserPassword,
  getUsers,
  removeUser,
  unblockUser
} from "services/ftpService";
import { getMachineInfo } from "services/virtualMachineService";
import { selectVirtualMachine } from "./dashboardSlice";
import { getToken, RootState } from "./store";

export interface FtpServerState {
  selectedVirtualMachineId?: string;

  ftpMachineInfo?: FtpVirtualMachineFullInfo;
  ftpMachineInfoState: RequestState;

  ftpUsers?: FtpUser[];
  ftpUsersState: RequestState;

  selectedUser?: string;
}

const initialState: FtpServerState = {
  ftpMachineInfoState: RequestState.Idle,
  ftpUsersState: RequestState.Idle,
};

export const getMachineInfoAsync = createAsyncThunk<
  VirtualMachineFullModel,
  string,
  { state: RootState }
>(
  "ftpServer/getMachineInfo",
  async (machineId, api) => {
    let token = getToken(api.getState()) as string;
    let info = await getMachineInfo(token, machineId);
    return info;
  },
  {
    condition: (_, api) => Boolean(api.getState()?.core?.token),
  }
);

export const getUsersAsync = createAsyncThunk<
  FtpUser[],
  string,
  { state: RootState }
>(
  "ftpServer/getUsers",
  async (machineId, api) => {
    let state = api.getState() as RootState;
    let token = getToken(state) as string;

    return await getUsers(token, machineId);
  },
  {
    condition: (_, api) => {
      let state = api.getState() as RootState;
      return (
        Boolean(state.core.token) &&
        Boolean(state.ftpServer.selectedVirtualMachineId)
      );
    },
  }
);

export const addUserAsync = createAsyncThunk<
  void,
  AddUserRequestModel,
  { state: RootState }
>(
  "ftpServer/addUser",
  async (addUserModel, api) => {
    let state = api.getState() as RootState;
    let token = getToken(state) as string;

    await addUser(token, addUserModel);
  },
  {
    condition: (_, api) => {
      let state = api.getState() as RootState;
      return (
        Boolean(state.core.token) &&
        Boolean(state.ftpServer.selectedVirtualMachineId)
      );
    },
  }
);

export const changeUserPasswordAsync = createAsyncThunk<
  void,
  ChangeUserPasswordRequestModel,
  { state: RootState }
>(
  "ftpServer/changeUserPassword",
  async (model, api) => {
    let state = api.getState() as RootState;
    let token = getToken(state) as string;

    await changeUserPassword(token, model);
  },
  {
    condition: (_, api) => {
      let state = api.getState() as RootState;
      return (
        Boolean(state.core.token) &&
        Boolean(state.ftpServer.selectedVirtualMachineId)
      );
    },
  }
);

export const changeUserBlockStateAsync = createAsyncThunk<
  ChangeBlockStateModel,
  ChangeBlockStateModel,
  { state: RootState }
>(
  "ftpServer/changeUserBlockState",
  async (model, api) => {
    let state = api.getState() as RootState;
    let token = getToken(state) as string;
    let user = model.user;
    let machineId = model.machineId;

    if (model.block) {
      await blockUser(token, machineId, user);
    } else {
      await unblockUser(token, machineId, user);
    }
    return model;
  },
  {
    condition: (_, api) => {
      let state = api.getState() as RootState;
      return (
        Boolean(state.core.token) &&
        Boolean(state.ftpServer.selectedVirtualMachineId)
      );
    },
  }
);

export const removeUserAsync = createAsyncThunk<
  void,
  RemoveUserModel,
  { state: RootState }
>(
  "ftpServer/removeUser",
  async (model, api) => {
    let state = api.getState() as RootState;
    let token = getToken(state) as string;

    await removeUser(token, model.machineId, model.user);
  },
  {
    condition: (_, api) => {
      let state = api.getState() as RootState;
      return (
        Boolean(state.core.token) &&
        Boolean(state.ftpServer.selectedVirtualMachineId)
      );
    },
  }
);

export const ftpServerSlice = createSlice({
  name: "ftpServer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(selectVirtualMachine, (state, action) => {
        state.selectedVirtualMachineId = action.payload.id;
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.ftpUsers = action.payload;
      })
      .addCase(getMachineInfoAsync.fulfilled, (state, action) => {
        state.ftpMachineInfoState = RequestState.Success;
        state.ftpMachineInfo = action.payload.machineInfo;
      })
      .addCase(getMachineInfoAsync.pending, (state) => {
        state.ftpMachineInfoState = RequestState.Loading;
      })
      .addCase(getMachineInfoAsync.rejected, (state) => {
        state.ftpMachineInfoState = RequestState.Failure;
      })
      .addCase(changeUserBlockStateAsync.fulfilled, (state, action) => {
        const user = state.ftpUsers?.find(item => item.username === action.payload.user);
        if (user) {
          user.isActive = action.payload.block;
        }
      })
      .addMatcher(
        (action) => action.type.startsWith("ftpServer/") && action.type.includes("User") && action.type.endsWith("/pending"),
        (state) => {
          state.ftpUsersState = RequestState.Loading;
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("ftpServer/") && action.type.includes("User") && action.type.endsWith("/fulfilled"),
        (state) => {
          state.ftpUsersState = RequestState.Success;
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("ftpServer/") && action.type.includes("User") && action.type.endsWith("/rejected"),
        (state) => {
          state.ftpUsersState = RequestState.Failure;
        }
      );
  },
});

//export const {} = ftpServerSlice.actions;
export default ftpServerSlice.reducer;
