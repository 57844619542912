import StyledButton from "components/StyledButton";
import { General } from "i18n/localizationText";
import { FtpCheckoutViewModel } from "models/viewModels";
import { Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { localizeYupError } from "utils/yup";

type FtpInformationProps = {
    total: number;

    onSubmit: (info: FtpCheckoutViewModel) => void;
};

const FtpInformation = (props: FtpInformationProps) => {
    const { total, onSubmit } = props;

    const { formState, register, handleSubmit } = useFormContext<FtpCheckoutViewModel>();
    const intl = useIntl();

    return (
        <>
            <Form.Group controlId="buy.ftpLogin">
                <Form.Label>
                    <FormattedMessage id={General.FtpLogin} />
                </Form.Label>
                <Form.Control
                    className="default-input"
                    type="text"
                    isInvalid={!!formState.errors.username}
                    {...register("username")}
                />
                <Form.Control.Feedback type="invalid">
                    {localizeYupError(intl, formState.errors.username)}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="buy.ftpPassword">
                <Form.Label>
                    <FormattedMessage id={General.FtpPassword} />
                </Form.Label>
                <Form.Control
                    className="default-input"
                    type="password"
                    isInvalid={!!formState.errors.password}
                    {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                    {localizeYupError(intl, formState.errors.password)}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="buy.ftpPasswordConfirmation">
                <Form.Label>
                    <FormattedMessage id={General.ConfirmPassword} />
                </Form.Label>
                <Form.Control
                    className="default-input"
                    type="password"
                    isInvalid={!!formState.errors.passwordConfirmation}
                    {...register("passwordConfirmation")}
                />
                <Form.Control.Feedback type="invalid">
                    {localizeYupError(intl, formState.errors.passwordConfirmation)}
                </Form.Control.Feedback>
            </Form.Group>

            <Row className="mt-2">
                <Col className="buy-server-cost-label">
                    <FormattedMessage
                        id={General.Cost}
                        values={{ value: total, currency: "$" }}
                    />
                </Col>
                <Col>
                    <StyledButton
                        className="w-100"
                        onClick={handleSubmit(onSubmit)}
                    >
                        <FormattedMessage id={General.BuyServer} />
                    </StyledButton>
                </Col>
            </Row>
        </>
    );
}

export default FtpInformation;