import { DialogParams, LoginOptions } from "@greatsumini/react-facebook-login";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { UseGoogleLoginProps } from "react-google-login";

const microsoftAuthConfig = {
  clientId: process.env.REACT_APP_AZURE_CLIENT_ID || "",
  redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI || "",
  scopes: ["user.read"]
};

export const facebookDialogParams : DialogParams = {
  client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID || "",
  redirect_uri: process.env.REACT_APP_FACEBOOK_REDIRECT_URI || "",
  state: "facebookAuth",
  scope: "email,public_profile",
  response_type: "code"
}

export const facebookLoginOptions : LoginOptions = {
  scope: "email,public_profile"
}

export const getGoogleConfig = (onSuccess: any, onFailure: any) : UseGoogleLoginProps => {
  return {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
    responseType: "code",
    scope: "profile",
    uxMode: "redirect",
    onSuccess: onSuccess,
    onFailure: onFailure
  };
};

export function validateToken(token: string) {
  let result = false;
  try {
    let jwt = jwt_decode(token) as JwtPayload;
    let exp = jwt.exp || 0;
    result = !(Date.now() >= exp * 1000);
  } catch (InvalidTokenError) {}
  return result;
};

export const redirectToMicrosoftAuth = () => {
  const uri = new URL("https://login.microsoftonline.com/common/oauth2/v2.0/authorize");
  uri.searchParams.set("client_id", microsoftAuthConfig.clientId);
  uri.searchParams.set("redirect_uri", microsoftAuthConfig.redirectUri);
  uri.searchParams.set("scope", microsoftAuthConfig.scopes.join(" "));
  uri.searchParams.set("response_type", "code");
  uri.searchParams.set("response_mode", "query");
  window.location.replace(uri.toString());
}