export type LocalizationText = Checkout | Dashboard | General | Landing | Login | Error;

export enum Checkout {
  AddNewUser = "checkout.addNewUser",
  AgreeWithTerms = "checkout.agreeWithTerms",
  CompleteOrder = "checkout.completeOrder",
  Subtitle = "checkout.subtitle",
  Title = "checkout.title",
  WeSentDetailsOnMail = "checkout.weSentDetailsOnMail",
}

export enum Dashboard {
  AboutText = "dashboard.about.text",
  AboutTitle = "dashboard.about.title",
  CancelSubscription = "dashboard.cancelSubscription",
  ChangeUserPassword = "dashboard.changeUserPassword",
  CreateFtpServerPlaceholder = "dashboard.createFtpServerPlaceholder",
  DeleteUser = "dashboard.deleteUser",
  DeleteUserModalDescription = "dashboard.deleteUserModal.description",
  EditServerName = "dashboard.editServerName",
  FreeSpace = "dashboard.freeSpace",
  FtpServerProcessingSubtitle = "dashboard.ftpProcessing.subtitle",
  FtpServerProcessingTitle = "dashboard.ftpProcessing.title",
  LockUser = "dashboard.lockUser",
  UnlockUser = "dashboard.unlockUser",
  FtpSpaceUsed = "dashboard.used",
  FtpSpaceFree = "dashboard.free",
  MySubscription = "dashboard.mySubscription",
  MoreApps = "dashboard.moreapps",
  NoServersPlaceholder = "dashboard.noServersPlaceholder",
  Refresh = "dashboard.refresh",
  RenewSubscription = "dashboard.renewSubscription",
  PauseSubscription = "dashboard.pauseSubscription",
  Search = "dashboard.search",
  ServerStateActive = "dashboard.serverState.active",
  ServerStateDeleted = "dashboard.serverState.deleted",
  ServerStateExpired = "dashboard.serverState.expired",
  ServerStateExpiring = "dashboard.serverState.expiring",
  SubscriptionHistory = "dashboard.subscriptionHistory",
  UnpauseSubscription = "dashboard.unpauseSubscription",
  UsersTitle = "dashboard.usersTitle",
  ValidityPeriod = "dashboard.validityPeriod",
  YourFtpServers = "dashboard.yourFtpServers"
}

export enum General {
  About = "general.about",
  AcceptTermsAndConditions = "general.acceptTermsAndConditions",
  BuyServer = "general.buyServer",
  BuySubscriptionToContinue = "general.buySubscriptionToContinue",
  Cancel = "general.cancel",
  Configuration = "general.configuration",
  ConfirmPassword = "general.confirmPassword",
  Confirmation = "general.confirmation",
  ContactUs = "general.contactUs",
  Continue = "general.continue",
  Cost = "general.cost",
  Dashboard = "general.dashboard",
  Delete = "general.delete",
  DiskSize = "general.diskSize",
  DiskSpaceValueFormat = "general.diskSpaceValueFormat",
  DurationValueFormat = "general.durationValueFormat",
  FtpLogin = "general.ftpLogin",
  FtpPassword = "general.ftpPassword",
  FtpServer = "general.ftpServer",
  Guide = "general.guide",
  IpAddressDynamic = "general.ipAddress.dynamic",
  IpAddressLabel = "general.ipAddress.label",
  IpAddressStatic = "general.ipAddress.static",
  Language = "general.language",
  Login = "general.login",
  Logout = "general.logout",
  Like = "general.like",
  Name = "general.name",
  New = "general.new",
  NoResponse = "general.noResponse",
  OrderCompleted = "general.orderCompleted",
  Password = "general.password",
  PaymentCompletedModalButtonText = "general.paymentCompletedModal.buttonText",
  PaymentCompletedModalText = "general.paymentCompletedModal.text",
  PaymentCompletedModalTitle = "general.paymentCompletedModal.title",
  Privacy = "general.privacy",
  PrivacyPolicy = "general.privacyPolicy",
  Proceed = "general.proceed",
  ProjectName = "general.projectName",
  Save = "general.save",
  Search = "general.search",
  ServerName = "general.serverName",
  SignUp = "general.signUp",
  Subscription = "general.subscription",
  SubscriptionDuration = "general.subscriptionDuration",
  Share = "general.share",
  TermsOfUse = "general.termsOfUse",
  User = "general.user",
  Username = "general.username"
}

export enum Landing {
  AboutText = "landing.aboutText",
  AboutTitle = "landing.aboutTitle",
  MainText = "landing.mainText",
  OrderSummary = "landing.orderSummary",
  Title = "landing.title"
}

export enum Login {
  Email = "login.email",
  FirstName = "login.firstName",
  LastName = "login.lastName",
  SignUpWith = "login.signUpWith",
  SignUpWithAnyBelow = "login.signUpWithAnyBelow"
}

export enum Error {
  AuthorizationFailed = "error.authorizationFailed",
  Internal = "error.internal",
  PasswordNotValid = "error.passwordNotValid",
  IncorrectMaxLength = "error.incorrectMaxLength",
  IncorrectMinLength = "error.incorrectMinLength",
  InvalidField = "error.invalidField",
  IsRequired = "error.isRequired",
  FtpUsername = "error.ftpUsername",
  FtpPassword = "error.ftpPassword",
  PasswordsNotMatch = "error.passwordsNotMatch"
}