import { AxiosError } from "axios";
import { RequestError } from "models/common";
import {
  CreateMachineStripe,
  GetPurchasesResponse,
  RenewMachineStripe
} from "models/purchaseModels";
import {
  authenticatedCoreAxios,
  coreAxios,
  handleAxiosError
} from "./apiService";

export const purchases = "purchases";
export const stripe = "stripe";
const purchasesEndpoint = (subRoute: string): string => purchases + subRoute;
const stripeEndpoint = (subRoute: string): string => stripe + subRoute;

export var getPurchases = (area: string) =>
  new Promise<GetPurchasesResponse>((resolve, reject) => {
    coreAxios
      .get<GetPurchasesResponse>(purchasesEndpoint(""), {
        params: {
          area: area,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var getPurchasesAuthorized = (token: string, area: string) =>
  new Promise<GetPurchasesResponse>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .get<GetPurchasesResponse>(purchasesEndpoint(""), {
        params: {
          area: area,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var getRenewPurchases = (token: string, machineId: string) =>
  new Promise<GetPurchasesResponse>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .get<GetPurchasesResponse>(purchasesEndpoint("/renew"), {
        params: { machineId: machineId },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var createMachineStripe = (token: string, model: CreateMachineStripe) =>
  new Promise<string>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .post<string>(stripeEndpoint("/createMachine"), model)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var renewMachineStripe = (token: string, model: RenewMachineStripe) =>
  new Promise<string>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .post<string>(stripeEndpoint("/renewMachine"), model)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var cancelSubscriptionStripe = (token: string, machineId: string) =>
  new Promise<any>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .post(stripeEndpoint("/cancelSubscription"), {
        machineId: machineId,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });

export var changeSubscriptionPauseStatusStripe = (
  token: string,
  machineId: string,
  pause: boolean
) =>
  new Promise<any>((resolve, reject) => {
    authenticatedCoreAxios(token)
      .post(stripeEndpoint("/changeSubscriptionCollectionStatus"), {
        machineId: machineId,
        pause: pause,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err: Error | AxiosError<RequestError>) => {
        reject(handleAxiosError(err));
      });
  });
