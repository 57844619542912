export const ROOT = "/";

export const CHECKOUT = "checkout";
export const DASHBOARD = "dashboard";
export const AUTH = "auth";

export const DASHBOARD_HOME = "home";
export const MACHINE_ID_PARAMETER = ":machineId";
export const DASHBOARD_HISTORY = "history";
export const DASHBOARD_ABOUT = "about";

export const AUTH_FACEBOOK = "facebook";
export const AUTH_MICROSOFT = "microsoft";

export const CHECKOUT_ABSOLUTE = `/${CHECKOUT}`;

export const DASHBOARD_HOME_ABSOLUTE = `/${DASHBOARD}/${DASHBOARD_HOME}`;
export const DASHBOARD_HISTORY_ABSOLUTE = `/${DASHBOARD}/${DASHBOARD_HISTORY}`;
export const DASHBOARD_ABOUT_ABSOLUTE = `/${DASHBOARD}/${DASHBOARD_ABOUT}`;

export const AUTH_FACEBOOK_ABSOLUTE = `/${AUTH}/${AUTH_FACEBOOK}`;
export const AUTH_MICROSOFT_ABSOLUTE = `/${AUTH}/${AUTH_MICROSOFT}`;