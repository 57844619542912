import classNames from "classnames";
import "./FlatCard.css";

type FlatCardProps = {
  children: React.ReactNode;
  className?: string;
};

const FlatCard = (props: FlatCardProps) => (
  <div className={classNames("flat-card", props.className)}>
    {props.children}
  </div>
);

export default FlatCard;
